import React from "react";
import S3 from "../../assets/SG 5.0 webview Mortgage Capital Banner.gif";
import DennisImage from "../../assets/Dennis.png"; // Add the path to Dennis's image
import TonyImage from "../../assets/Tony.png"; // Add the path to Tony's image
import { FaPhone, FaEnvelope, FaLinkedin, FaTwitter, FaCalendarAlt } from "react-icons/fa";
import PhoneCall from "../../assets/PhoneCall.png";
import Envelope from "../../assets/Envelope.png";
import FacebookLogo from "../../assets/FacebookLogo.png";
import TwitterLogo from "../../assets/TwitterLogo.png";
import CalendarCheck from "../../assets/CalendarCheck.png";
import LinkedinLogo from "../../assets/LinkedinLogo.png";
import CareLeft from "../../assets/CareLeft.png";
import CareRight from "../../assets/CareRight.png";
import "./Mortgage.css";

const Mortgage = () => {
  return (
    <div className="Mortgage">
      {/* <h1>SG Mortgage Capital</h1>
      <h2>Commercial Mortgages. Residential Mortgages. Alternative Lending.</h2> */}
      <div className="MortgagePic">
        <img src={S3} alt="Mortgage Overview" />
      </div>

      <p className="section-headings-center">
        Denise has excelled in the mortgage space since starting her career in
        2007 in residential lending. Having started in the industry as a broker,
        diverse offerings have always been part of her service. Denise has a
        long history of relationships with lenders and industry suppliers that
        assist in efficient deal management. With a strong business background and a passion for great client service
        she decided to focus on the investor and business owner needs where she
        excels at the deal structure and client satisfaction. Through the years
        Denise has shown a commitment to excellence in lender and client
        communication and the ability to assess borrower solutions quickly and
        effectively. Denise understands that your mortgage and property are extremely
        powerful tools and she can help you use them!
      </p>

      <div className="Proff22">
        <div className="Profile22">
          <img src={DennisImage} alt="Dennis Benninger" className="Profile22Image" />
          <div className="Profile22Details">
            <h3>Dennis Benninger</h3>
            <p>Mortgage Broker</p>
            <div className="icons">
                  <ul>
                    <li>
                      <img src={PhoneCall} alt="Phone Call" />
                    </li>
                    <li>
                      <img src={Envelope} alt="Envelope" />
                    </li>
                    <li>
                      <img src={FacebookLogo} alt="Facebook" />
                    </li>
                    <li>
                      <img src={LinkedinLogo} alt="LinkedIn" />
                    </li>
                    <li>
                      <img src={TwitterLogo} alt="Twitter" />
                    </li>
                    <li>
                      <img src={CalendarCheck} alt="Calendar Check" />
                    </li>
                  </ul>
                  <button className="ApplyButton">Apply with Dennis</button>
                </div>
          </div>
        </div>
        <div className="Profile22">
          <img src={TonyImage} alt="Tony Maruna" className="Profile22Image" width={50} height={50}  />
          <div className="Profile22Details">
            <div>
            <h3>Tony Maruna</h3>
            <p>Mortgage Broker</p>
            </div>
            <div className="icons">
                  <ul>
                    <li>
                      <img src={PhoneCall} alt="Phone Call" />
                    </li>
                    <li>
                      <img src={Envelope} alt="Envelope" />
                    </li>
                    <li>
                      <img src={FacebookLogo} alt="Facebook" />
                    </li>
                    <li>
                      <img src={LinkedinLogo} alt="LinkedIn" />
                    </li>
                    <li>
                      <img src={TwitterLogo} alt="Twitter" />
                    </li>
                    <li>
                      <img src={CalendarCheck} alt="Calendar Check" />
                    </li>
                  </ul>
                </div>
            {/* <b qutton className="ApplyButton">Apply with Tony</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mortgage;
