import React, { useState, useEffect } from "react";
import Folder from "../../assets/Folder.png";
import { IoChevronBackSharp, IoCloseSharp } from "react-icons/io5";
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai";
import Pdf from "../../assets/Pdf.png";
import Image from "../../assets/image.png";
import "./RenderFile.css";
import DataHeader2 from "../DataHeader/DataHeader2";
import { ipv4 } from "../../utils/config";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ImSpinner9 } from "react-icons/im";
import { FaPlus } from "react-icons/fa6";
import FolderFile from "./Folder/FolderFile";

const RenderFile = ({ fileType, onBackClick, userData }) => {
  console.log(fileType);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [uploadedFileType] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("All");
  const [userList, setUserList] = useState([]);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  const Email = useSelector((state) => state.user.userDetails?.email);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const userid = useSelector((state) => state.user.userDetails?.user_id);
  const [folders, setFolders] = useState([]);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);

  // Add this function to handle folder clicks
  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
  };
  const handleDeleteFolder = async (folderId) => {
    try {
      setIsLoading(true)
      const response = await axios.delete(`${ipv4}/delete-sub-folder`, {
        data: { userId: userid, folderId }
      });

      if (response.status === 200) {
        // toast.success('Folder deleted successfully');
        setFolders(folders.filter(folder => folder._id !== folderId));
        setFolderToDelete(null);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error deleting folder:', error);
      toast.error('Failed to delete folder: ' + (error.response?.data?.message || error.message));

    }
  };

  useEffect(() => {
    const fetchFolders = async () => {
      setIsLoading(true)
      try {
        const response = await axios.post(`${ipv4}/get-sub-folders`, {
          userId: userid,
          category: fileType
        });
        setFolders(response.data);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Error fetching folders:", error);
        toast.error("Failed to fetch folders");
      }
    };

    fetchFolders();
  }, [userid, fileType]);

  useEffect(() => {
    const processUserData = (data) => {
      if (data[fileType] && Array.isArray(data[fileType].files)) {
        return data[fileType].files.map((file) => ({
          ...file,
          formattedDate: formatDate(file.createdAt),
        }));
      }
      return [];
    };

    let allFiles = [];
    if (Array.isArray(userData)) {
      allFiles = userData.flatMap(processUserData);
    } else if (typeof userData === "object" && userData !== null) {
      allFiles = processUserData(userData);
    }

    setFilteredFiles(allFiles);

    // Extract unique user list
    const users = Array.from(new Set(allFiles.map((file) => file.addedBy)));
    setUserList(["All", ...users]);
  }, [fileType, userData]);
  const handleCreateFolder = async () => {
    try {
      const response = await axios.post(`${ipv4}/create-sub-folders`, {
        name: newFolderName,
        category: fileType,
        userId: userid
      });

      if (response.status === 201) {
        // toast.success('Folder created successfully');
        setFolders([...folders, response.data.folder]);
        setIsCreateFolderModalOpen(false);
        setNewFolderName('');
      }
    } catch (error) {
      console.error('Error creating folder:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      toast.error('Failed to create folder: ' + (error.response?.data?.message || error.message));
    }
  };
  useEffect(() => {
    filterFilesByUser(selectedUser);
  }, [selectedUser]);

  const filterFilesByUser = (user) => {
    const processUserData = (data) => {
      if (data[fileType] && Array.isArray(data[fileType].files)) {
        return data[fileType].files.map((file) => ({
          ...file,
          user_id: data.user_id,
          formattedDate: formatDate(file.createdAt),
        }));
      }
      return [];
    };

    let allFiles = [];
    if (Array.isArray(userData)) {
      allFiles = userData.flatMap(processUserData);
    } else if (typeof userData === "object" && userData !== null) {
      allFiles = processUserData(userData);
    }

    if (user === "All") {
      setFilteredFiles(allFiles);
    } else {
      setFilteredFiles(allFiles.filter((file) => file.addedBy === user));
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.some((selectedFile) => selectedFile === file.fileId)
        ? prevSelected.filter((selectedFile) => selectedFile !== file.fileId)
        : [...prevSelected, file.fileId]
    );
  };

  const handleDeleteSelected = async () => {
    if (selectedFiles.length === 0) {
      toast.warn("Please select files to delete");
      return;
    }

    try {
      setIsLoading(true);

      // Create an array of deletion requests
      const deleteRequests = selectedFiles.map((fileId) => {
        const file = filteredFiles.find((f) => f.fileId === fileId);
        return {
          user_id: file.user_id,
          fileCategory: fileType,
          fileId: file.fileId,
        };
      });

      // Send a request to delete files
      const response = await axios.post(`${ipv4}/delete-file`, {
        files: deleteRequests,
      });

      if (response.status === 200) {
        // Remove deleted files from filteredFiles
        const updatedFiles = filteredFiles.filter(
          (file) => !selectedFiles.includes(file.fileId)
        );
        setFilteredFiles(updatedFiles);
        setSelectedFiles([]);

        // Recalculate user list based on the updated filteredFiles
        const users = Array.from(
          new Set(updatedFiles.map((file) => file.addedBy))
        );
        setUserList(["All", ...users]);

        window.alert(`file(s) deleted successfully`);
      } else {
        throw new Error("Failed to delete files");
      }
    } catch (error) {
      console.error("Error deleting files:", error);
      window.alert("An error occurred while deleting files. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const truncateFileName = (fileName, maxLength = 14) => {
    if (!fileName) return ''; // Return empty string if fileName is undefined or null
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    const truncatedName = nameWithoutExtension.substring(0, maxLength - extension.length - 3);
    return `${truncatedName}...${extension}`;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleFileTypeClick = (fileType) => {
    setSelectedFileType(fileType);
  };

  const handleFileClick = (file) => {
    setPreviewFile(file);
  };

  const handleDownload = (file) => {
    const blob = new Blob([new Uint8Array(file.data.data)], {
      type: file.fileType,
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = file.filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleClosePreview = () => {
    setPreviewFile(null);
  };
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  if (selectedFolder) {
    return (
      <FolderFile
        onBackClick={() => setSelectedFolder(null)}
        folderId={selectedFolder._id}
      />
    );
  }
  if (isLoading) {
    return (
      <div className="loader">
        <ImSpinner9 className="spinner" />
      </div>
    );
  }

  const FolderItem = ({ folder, onClick }) => (
    <div className="filesData" onClick={onClick}>
      <button
        className="deleteIcon"
        onClick={(e) => {
          e.stopPropagation();
          setFolderToDelete(folder);
        }}
        style={{ alignSelf: 'flex-end' }}
      >
        <AiOutlineDelete size={15} color=" #6464f5" />
      </button>
      <div className="folderContainer">
        <img src={Folder} alt="Folder Icon" className="folderImg" />
      </div>
      <div className="fileInfo">
        <h1>{truncateFileName(folder.name || 'Unnamed Folder')}</h1>
        <h3>{formatDate(folder.createdAt) || 'No date'}</h3>
        <h3>{' '}</h3>
      </div>
    </div>
  );
  return (
    <div className="RenderFile">
      <DataHeader2
        uploadedFileType={uploadedFileType}
        selectedFileType={selectedFileType}
        onFileTypeClick={handleFileTypeClick}
      />
      {isCreateFolderModalOpen && (
        <div className="Upload-modal">
          <div className="Upload-modal-content">
            <h2>Create New Folder</h2>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Enter folder name"
            />
            <button onClick={handleCreateFolder}>Create</button>
            <button onClick={() => setIsCreateFolderModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
      <div className="renderName">
        <div className="fileTypeTitle">
          <h1>
            <span onClick={onBackClick} className="backButton">
              {" "}
              <IoChevronBackSharp size={14} />
            </span>{" "}
            Data Files : <span>{fileType === 'Wishlist' ? 'Docs For Associates to Review' : fileType}</span>
          </h1>
        </div>
        <div className="btn-line">
          {selectedFiles.length > 0 ? (
            <div className="deleteDiv">
              <button onClick={handleDeleteSelected} className="deleteButton">
                <AiOutlineDelete size={15} /> Delete
              </button>
            </div>
          ) : (
            <>
              <div className="newFolder">
                <button className="folderButton" onClick={() => setIsCreateFolderModalOpen(true)}>
                  <FaPlus size={15} /> New Folder
                </button>
              </div>
              {userRole === 'Admin' && (
                <div className="userFilterDropdown">
                  <select
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    {userList.map((user, index) => (
                      <option key={index} value={user}>{user}</option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
        </div>

      </div>
      {folderToDelete && (
        <div className="Upload-modal">
          <div className="Upload-modal-content">
            <h2>Delete Folder</h2>
            <p>Are you sure you want to delete the folder <strong>{folderToDelete.name}</strong>?</p>
            <button onClick={() => handleDeleteFolder(folderToDelete._id)}>Delete</button>
            <button onClick={() => setFolderToDelete(null)}>Cancel</button>
          </div>
        </div>
      )}



      <div className="file-display">
        {folders.length > 0 && (
          <>
            {folders.map((folder) => (
              <div key={folder._id} onClick={() => handleFolderClick(folder)}>
                <FolderItem folder={folder} />
              </div>
            ))}
            <hr />
          </>
        )}
        {filteredFiles.map((file, index) => (
          <div key={index} className="filesData">
            <div className="folderContainer">
              <input
                type="checkbox"
                checked={selectedFiles.includes(file.fileId)}
                onChange={() => handleFileSelect(file)}
                className="fileCheckbox"
              />
              <img
                src={file.fileType && file.fileType.startsWith("image/") ? Image : Pdf}
                alt="File Icon"
                className="folderImg"
                onClick={() => handleFileClick(file)}
              />
              <button
                className="downloadIcon"
                onClick={() => handleDownload(file)}
              >
                <AiOutlineDownload size={15} />
              </button>
            </div>
            <div className="fileInfo">
              <h1>{truncateFileName(file.filename)}</h1>
              <h3>{file.formattedDate}</h3>
              <h3>{file.addedBy}</h3>
            </div>
          </div>
        ))}
      </div>

      {previewFile && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={handleClosePreview}>
              <IoCloseSharp size={20} />
            </button>
            {previewFile.fileType.startsWith("image/") ? (
              <img
                src={`data:${previewFile.fileType};base64,${arrayBufferToBase64(
                  previewFile.data.data
                )}`}
                alt={previewFile.filename}
                style={{ maxWidth: "100%", maxHeight: "500px" }}
              />
            ) : previewFile.fileType === "application/pdf" ? (
              <embed
                src={`data:${previewFile.fileType};base64,${arrayBufferToBase64(
                  previewFile.data.data
                )}`}
                type={previewFile.fileType}
                style={{ width: "100%", height: "500px" }}
              />
            ) : (
              <p>No preview available.</p>
            )}
          </div>
        </div>
      )}
      {/* <ToastContainer></ToastContainer> */}
    </div>
  );
};

export default RenderFile;
