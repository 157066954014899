import React, { useState } from "react";
import "./ResourcesData.css";

import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdArrowForward } from "react-icons/md";

import calculatorSoup from "../../assets/calculator-soup.png"
import dinky from "../../assets/dinky.png"
import szam from "../../assets/szam.png"
import manulife from "../../assets/manulife.png"
import newport from "../../assets/newport.svg"
import sgreot from "../../assets/sgreot.png"
import sgredt from "../../assets/sgredt.png"
import westpac from "../../assets/westpac.png"
import olympia from "../../assets/olympia.svg"
import wrsbc from "../../assets/wrsbc.svg"
import comhub from "../../assets/comhub.png"
import accctr from "../../assets/accctr.svg"
import edckw from "../../assets/edckw.svg"
import gkwcc from "../../assets/gkwcc.png"
import kwfc from "../../assets/kwfc.png"
import innoguelph from "../../assets/innoguelpg.svg"
import boc from "../../assets/boc.svg"
import statscan from "../../assets/statscan.svg"
import bloomberg from "../../assets/bloomberg.svg"
import yfin from "../../assets/yfin.svg"
import cafc from "../../assets/cafc.svg"
import fintrac  from "../../assets/fintrac.svg"
import aon from "../../assets/aon.png"
import marsh from "../../assets/marsh_rgb_w.svg"
import wtw from "../../assets/wtw.svg"
import aig from "../../assets/aig.svg"
import zurich from "../../assets/zurich.svg"
import allianz from "../../assets/allianz-logo.svg"
import chubb from "../../assets/chubb.png"
import libmut from "../../assets/libmut.svg"
import trvls from "../../assets/trvls.png"
import rma from "../../assets/rma.svg"
import canlife from "../../assets/canlife.svg"
import bmo from "../../assets/bmo.png"
import sunlife from "../../assets/sunlife.svg"
import indall from "../../assets/indall.svg"
import emplife from "../../assets/emplife.svg"
import coop from "../../assets/co-op.svg"
import intact from "../../assets/intact.svg"
import aviva from "../../assets/aviva.svg"
import dj from "../../assets/dj.svg"
import ontprob from "../../assets/ontprob.svg"
import profile from "../../assets/profile.svg"
import xero from "../../assets/xero.png"
import sage from "../../assets/sage.svg"
import freshbooks from "../../assets/freshbooks.svg"
import wave from "../../assets/wave.svg"
import zoho from "../../assets/zoho.svg"
import kashoo from "../../assets/kashoo.svg"
import qbo from "../../assets/qbo.svg"
import rbc from "../../assets/rbc.svg"
import td from "../../assets/td.png"
import scotia from "../../assets/scotia.svg"
import bmo2 from "../../assets/bmo.svg"
import cibc from "../../assets/cibc.svg"
import nbc from "../../assets/nbc.png"
import hsbc from "../../assets/hsbc.png"
import lauretian from "../../assets/lauretian.svg"
import meridian from "../../assets/meridian.svg"
import duca from "../../assets/duca.svg"
import alterna from "../../assets/alterna.png"
import firston from "../../assets/firston.svg"
import coastcap from "../../assets/coastcap.svg"
import libro from "../../assets/libro.svg"
import vancity from "../../assets/vancity.svg"
import servus from "../../assets/servus.png"
import affinity from "../../assets/affinity.svg"
import conexus from "../../assets/conexus.svg"
import coastal from "../../assets/coastal.svg"
import connect from "../../assets/connect.png"

const ResourcesData = () => {
    const [isWealthOpen, setWealthIsOpen] = useState(false);
    const [isPortfolioOpen, setPortfolioIsOpen] = useState(false);
    const [isInsuranceOpen, setInsuranceIsOpen] = useState(false);
    const [isEstateOpen, setEstateIsOpen] = useState(false);
    const [isTaxOpen, setTaxIsOpen] = useState(false);
    const [isBusinessOpen, setBusinessIsOpen] = useState(false);
    const [isFinancingOpen, setFinancingIsOpen] = useState(false);
    const [isOtherOpen, setOtherIsOpen] = useState(false);

    const handleClick = (tabName) => {
        switch(tabName) {
            case "wealth":
                setWealthIsOpen(!isWealthOpen);
                break;
            case "portfolio":
                setPortfolioIsOpen(!isPortfolioOpen);
                break;
            case "insurance":
                setInsuranceIsOpen(!isInsuranceOpen);
                break;
            case "estate":
                setEstateIsOpen(!isEstateOpen);
                break;
            case "tax":
                setTaxIsOpen(!isTaxOpen);
                break;
            case "business":
                setBusinessIsOpen(!isBusinessOpen);
                break;
            case "financing":
                setFinancingIsOpen(!isFinancingOpen);
                break;
            case "other":
                setOtherIsOpen(!isOtherOpen);
                break;
        }
    }

  return (
    <div className="ServicesPage">
        <div className="ResourcesGrid">
            <div className="resource-1">
                <h4 onClick={() => {handleClick("wealth")}}>Wealth Planning {isWealthOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isWealthOpen && <div className="resource-items-container">
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/investment-calculator.php">
                        InvCalc <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/savings-calculator.php">
                        SavCalc <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/loan-calculator.php">
                        LoanCalc <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/mortgage-calculator.php?do=pop">
                        MortCalc <MdArrowForward />
                        </a>
                    </div> 
                </div>}
            </div>
            <div className="resource-2">
                <h4 onClick={() => {handleClick("portfolio")}}>Portfolio Management {isPortfolioOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isPortfolioOpen && <div className="resource-items-container">
                    <div className="resource-item flex">
                        <a target="_blank" href="http://szamcapital.com/">
                        SZAM <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="http://www.manulife.com/">
                        Manulife <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://newportprivatewealth.ca">
                        NewLons <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.smvcapitalmarkets.com/Company/sgreot">
                        SGREOT <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.smvcapitalmarkets.com/Company/sgredtrust">
                        SGREDT <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="http://szamcapital.com/">
                        15MKT <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.westernpacifictrust.com">
                        WestPac <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.olympiatrust.com">
                        Olympia <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.waterlooregionsmallbusiness.com/">
                        WRSBC <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.communitech.ca">
                        ComHub <MdArrowForward />
                        </a>
                    </div> 
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.acceleratorcentre.com">
                        AccCtr <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.waterlooedc.ca">
                        EDCKW <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.greaterkwchamber.com">
                        GKWCC <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.kwcf.ca">
                        KWCF <MdArrowForward />
                        </a>
                    </div> 
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.innovationguelph.ca">
                        InnoGuelph <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.bankofcanada.ca">
                        BoC <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.statcan.gc.ca">
                        StatsCan <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.bloomberg.com">
                        Bloomberg <MdArrowForward />
                        </a>
                    </div> 
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.finance.yahoo.com">
                        YFin <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://antifraudcentre-centreantifraude.ca/index-eng.htm">
                        CAFC <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://fintrac-canafe.canada.ca/intro-eng">
                        FINTRAC <MdArrowForward />
                        </a>
                    </div> 
                </div>}
            </div>
            <div className="resource-3">
                <h4 onClick={() => {handleClick("insurance")}}>Insurance Management {isInsuranceOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isInsuranceOpen && <div className="resource-items-container">
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.aon.com">
                        Aon <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.marsh.com">
                        Marsh <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.willistowerswatson.com">
                        WTW <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.ajg.com">
                        Gallagher <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.zurich.com">
                            Zurich <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.allianz.com">
                        Allianz <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.chubb.com">
                        Chubb <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.libertymutual.com">
                        LibMut <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.travelers.com">
                        Trvls <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.rmahq.org">
                        RMA <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.manulife.ca">
                        Manulife <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.canadalife.com">
                        CanLife <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.bmo.com/insurance">
                        BMO <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.sunlife.ca">
                        SunLife <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.greatwestlife.com">
                        GWL <MdArrowForward />
                        </a>
                    </div>
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.ia.ca">
                        IndAll <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.empire.ca">
                        EmpLife <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.cooperators.ca">
                        Co-op <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.intact.ca">
                        Intact <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.avivacanada.com">
                        Aviva <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.desjardins.com">
                        Desjardins <MdArrowForward />
                        </a>
                    </div>  
                </div>}
            </div>
            <div className="resource-4">
                <h4 onClick={() => {handleClick("estate")}}>Estate Planning {isEstateOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isEstateOpen && <div className="resource-items-container">
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.thegreatsuccession.com/">
                            The Great Succession <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.canada.ca/en/employment-social-development/corporate/seniors-forum-federal-provincial-territorial/will-funeral-plan.html">
                        Canada.ca <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.ontario.ca/page/apply-probate-estate">
                        OntProb <MdArrowForward />
                        </a>
                    </div>    
                </div>}
            </div>
            <div className="resource-5">
                <h4 onClick={() => {handleClick("tax")}}>Tax & Accounting {isTaxOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isTaxOpen && <div className="resource-items-container">
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/gst-hst-netfile.html">
                        CRA <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://profile.intuit.ca/free-trial/">
                        Intuit <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.xero.com/ca/">
                        Xero <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.sage.com/ca/">
                        Sage <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.freshbooks.com">
                        FreshBooks <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.waveapps.com">
                        WaveAcct <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.zoho.com/ca/books/">
                        ZohoBooks <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.kashoo.com">
                        Kashoo <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://quickbooks.intuit.com/ca/">
                        QBO <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.canada.ca/en/revenue-agency/services/e-services/cra-login-services.html">
                        CRA <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.ontario.ca">
                        ServOnt <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.canada.ca/en/employment-social-development/corporate/portfolio/service-canada.html">
                        ServCan <MdArrowForward />
                        </a>
                    </div>  
                </div>}
            </div>
            <div className="resource-6">
                <h4 onClick={() => {handleClick("business")}}>Business Services {isBusinessOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isBusinessOpen && <div className="resource-items-container"> 
                </div>}
            </div>
            <div className="resource-7">
                <h4 onClick={() => {handleClick("financing")}}>Financing & Morgage {isFinancingOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isFinancingOpen && <div className="resource-items-container">
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.rbc.com">
                        RBC <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.td.com">
                        TD <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.scotiabank.com">
                        Scotiabank <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.bmo.com">
                        BMO <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.cibc.com">
                        CIBC <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.nbc.ca">
                        NBC <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.hsbc.ca">
                        HSBC <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.laurentianbank.ca">
                        Laurentian <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.desjardins.com">
                        Dj <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.meridiancu.ca">
                        Meridian <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.duca.com">
                        DUCA <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.alterna.ca">
                        Alterna <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.firstontario.com">
                        FirstON <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.coastcapitalsavings.com">
                        CoastCap <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.libro.ca">
                        Libro <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.vancity.com">
                        Vancity <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.servus.ca">
                        Servus <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.affinitycu.ca">
                        Affinity <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" href="https://www.conexus.ca">
                        Conexus <MdArrowForward />
                        </a>
                    </div>   
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.cccu.ca">
                        Coastal <MdArrowForward />
                        </a>
                    </div>  
                    <div className="resource-item flex">
                        <a target="_blank" className="flex center" href="https://www.connectfirstcu.com">
                        Connect <MdArrowForward />
                        </a>
                    </div>  
                </div>}
            </div>
            <div className="resource-8">
                <h4 onClick={() => {handleClick("other")}}>Other Useful Links {isOtherOpen ? < MdKeyboardArrowUp /> : < MdKeyboardArrowDown/>}</h4>
                {isOtherOpen && <div className="resource-items-container">  
                </div>}
            </div>
            <div className="resource-9"></div>
        </div>
    </div>
  );
};

export default ResourcesData;