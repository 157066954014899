import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./Upload.css";
import FileIcon from "../../assets/file.png";  // Correctly import the image
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ipv4, reload } from "../../utils/config";

const Upload = ({ onFileTypeSelected, fileType, onFileUploaded }) => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userId = useSelector((state) => state.user.userDetails?.user_id);
  const username = useSelector((state) => state.user.userDetails?.lastName);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const selectedFileType = useSelector((state) => state.user.selectedFileType);
  const selectedFolder = useSelector((state) => state.user.selectedFolder);
  // const setSelectedFileType = useSelector((state) => state.user.setSelectedFileType);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [file, setFile] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleUpload = async () => {
    if (isAuthenticated) {
      if (!file) {
        window.alert("Please select a file.");
        return;
      }
      if (!selectedFolder) {
        window.alert("select a folder");
        return;
      }
      console.log("Upload File Type ", selectedFolder);
      console.log(userId);
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", userId);
      formData.append("username", username);
      formData.append("folder_name", selectedFolder);

      try {
        const response = await axios.post(ipv4 + "/add_files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Success:", response.data);
        // setSelectedFileType(null);
        setFile(null);
        setIsLoading(false);
        window.alert("File uploaded succesfully");
        onFileUploaded();
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      window.alert("Please login first to upload a file.");
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 25 * 1024 * 1024; // 25MB in bytes

    if (file.size > maxSize) {
      window.alert("File size larger than 25MB");
      e.target.value = null; // Reset the file input
    } else {
      console.log("File size (bytes):", file.size);
      setFile(file);
    }
  };

  const handleUploadFile = async () => {
    setIsCameraOpen(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
      console.error("Error accessing camera: ", err);
    }
  };

  const handleTakePhoto = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
      setFile(file);
      setIsCameraOpen(false);
      videoRef.current.srcObject.getTracks().forEach(track => track.stop()); // Stop the camera
    }, "image/jpeg");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    setFile(file);
    // promptFileTypeSelection();
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  };

  return (
    <div className="Upload">
      <div className="upload-header">
        <img src="https://cm4-production-assets.s3.amazonaws.com/1718082788338-sentsentx.jpeg" alt="sentry logo" />
        <h1>Upload File</h1>
      </div>
      <div className="dotted" onDragOver={handleDragOver} onDrop={handleDrop}>
        <img src={FileIcon} alt="File Icon" />
        <label htmlFor="fileInput">
          <p>
            <span>Click to upload</span> or drag and drop
          </p>
          <input
            type="file"
            id="fileInput"
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </label>
        <h6>(Max. File size: 25 MB)</h6>
        {file && <h6>{file.name}</h6>}
        <span className="photo-upload-trigger" onClick={handleUploadFile}>Click to take a photo</span>
      </div>
      <div
        className={isAuthenticated ? "btn3" : "btn3-disabled"}
        onClick={handleUpload}
        style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
      >
        <div>
          {isLoading
            ? "Uploading..."
            : isFileUploaded
              ? "Uploaded! Click to upload another"
              : "Upload a file"}
        </div>
      </div>

      {isCameraOpen && (
        <div className="camera-overlay">
          <div className="camera-popup">
            <button className="close-camera-btn" onClick={handleCloseCamera}>
              &times;
            </button>
            <video ref={videoRef} width="640" height="480" autoPlay />
            <button className="take-photo-btn" onClick={handleTakePhoto}>
              Take Photo
            </button>
            <canvas ref={canvasRef} width="640" height="480" style={{ display: "none" }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Upload;
