import React, { useState } from "react";
import "./NewsData.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { ImSpinner9 } from "react-icons/im";
import Insta1 from "../../assets/insta1.png";
import Insta2 from "../../assets/insta2.png";
import Insta3 from "../../assets/insta3.png";
import Insta4 from "../../assets/insta4.png";
import Insta5 from "../../assets/insta5.png";
import Insta6 from "../../assets/insta6.png";
import Insta7 from "../../assets/insta7.png";
import Insta8 from "../../assets/insta8.png";
import Insta9 from "../../assets/insta9.png";
import Insta10 from "../../assets/insta10.png";
import Insta11 from "../../assets/insta11.png";
import Insta12 from "../../assets/insta12.png";

const NewsData = () => {
  const instaImages = [
    { src: Insta1, link: "https://www.instagram.com/p/C-kbLmEB2b3/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta2, link: "https://www.instagram.com/p/C9UmicovMby/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta3, link: "https://www.instagram.com/p/C7EZ19AvnDm/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta4, link: "https://www.instagram.com/p/C6_XG6sPvBq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta5, link: "https://www.instagram.com/p/C66GrJRt-Ij/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta6, link: "https://www.instagram.com/p/C6v1J4gNKcw/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta7, link: "https://www.instagram.com/p/C6oFFJKJpO7/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta8, link: "https://www.instagram.com/p/C6d4vunh5lt/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta9, link: "https://www.instagram.com/p/C6YqkweqQZO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta10, link: "https://www.instagram.com/p/C4N4UefOCm0/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta11, link: "https://www.instagram.com/p/CxF0qMJNaqU/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
    { src: Insta12, link: "https://www.instagram.com/p/CuJ2n8htN_A/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < instaImages.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentImages = instaImages.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="NewsData">
      {/* <h1>Blogs</h1> */}
      {instaImages.length === 0 ? (
        <div className="loader2">
          <ImSpinner9 className="spinner" />
        </div>
      ) : (
        <>
          <div className="news-grid">
            {currentImages.map((item, index) => (
              <div key={index} className="news-item">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <div className="news-img">
                    <img src={item.src} alt={`Instagram post ${index + 1}`} />
                  </div>
                </a>
              </div>
            ))}
          </div>
          <div className="pagination">
            <div
              className={`btn-1 ${currentPage === 1 ? "disabled" : ""}`}
              onClick={handlePreviousPage}
            >
              <IoIosArrowBack />
            </div>
            <div
              className={`btn-2 ${
                startIndex + itemsPerPage >= instaImages.length
                  ? "disabled"
                  : ""
              }`}
              onClick={handleNextPage}
            >
              <IoIosArrowForward />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsData;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./NewsData.css";
// import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
// import { FaArrowUpRightFromSquare } from "react-icons/fa6";
// import { IoIosArrowBack } from "react-icons/io";
// import { ImSpinner9 } from "react-icons/im";
// import { ipv4 } from "../../utils/config";

// const NewsData = () => {
//   const [news, setNews] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const itemsPerPage = 6;

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(
//           `${ipv4}/news`
//         );
//         const filteredNews = response.data.filter(
//           (article) => article.urlToImage
//         );
//         setNews(filteredNews);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching news data", error);
//         setLoading(false);
//       }
//     };

//     fetchNews();
//   }, []);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate();
//     const daySuffix =
//       day % 10 === 1 && day !== 11
//         ? "st"
//         : day % 10 === 2 && day !== 12
//         ? "nd"
//         : day % 10 === 3 && day !== 13
//         ? "rd"
//         : "th";
//     const month = date.toLocaleString("default", { month: "short" });
//     const year = date.getFullYear();
//     return `${day}${daySuffix} ${month} ${year}`;
//   };

//   const truncateTitle = (title, maxLength = 50) => {
//     return title.length > maxLength
//       ? `${title.substring(0, maxLength)}...`
//       : title;
//   };

//   const handleNextPage = () => {
//     setCurrentPage((prevPage) => prevPage + 1);
//   };

//   const handlePreviousPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const currentNews = news.slice(startIndex, startIndex + itemsPerPage);

//   return (
//     <div className="NewsData">
//       <h1>Blogs</h1>
//       {loading ? (
//         <div className="loader2">
//           <ImSpinner9 className="spinner" />
//         </div>
//       ) : (
//         <>
//           <div className="news-grid">
//             {currentNews.map((article, index) => (
//               <div key={index} className="news-item">
//                 <div className="news-img">
//                   <img src={article.urlToImage} alt={article.title} />
//                 </div>
//                 <div className="news-time">
//                   <div>
//                     <p>{formatDate(article.publishedAt)}</p>
//                   </div>
//                   <div>
//                     <p>
//                       {Math.ceil(article.content.split(" ").length / 200)} mins
//                       read
//                     </p>
//                   </div>
//                 </div>
//                 <h3>{truncateTitle(article.title, 50)}</h3>
//                 <p className="para2">
//                   {article.description.length > 150
//                     ? `${article.description.substring(0, 100)}...`
//                     : article.description}
//                 </p>
//                 <div className="read">
//                   <a
//                     href={article.url}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     Read More <FaArrowUpRightFromSquare />
//                   </a>
//                 </div>
//                 <div className="news-details">
//                   <div>
//                     {" "}
//                     <p>Share post via</p>
//                   </div>
//                   <div className="share-icons">
//                     <FaFacebook />
//                     <FaTwitter />
//                     <FaLinkedin />
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="pagination">
//             <div
//               className="btn-1"
//               onClick={handlePreviousPage}
//               disabled={currentPage === 1}
//             >
//               <IoIosArrowBack />
//             </div>
//             <div
//               className="btn-2"
//               onClick={handleNextPage}
//               disabled={startIndex + itemsPerPage >= news.length}
//             >
//               Next
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default NewsData;
