import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ipv4 } from "../utils/config";

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (formData, { rejectWithValue }) => {
    const { email, ...restData } = formData;
    try {
      const response = await axios.put(
        `${ipv4}/update_user/${encodeURIComponent(email)}`,
        { updateData: restData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfileLocally = (formData) => (dispatch) => {
  dispatch(updateUserProfile(formData));
};

export const updateProfilePicture = createAsyncThunk(
  "user/updateProfilePicture",
  async ({ userId, userPictureUrl }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${ipv4}/users/${userId}/updateUserPicture`,
        {
          userPicture: userPictureUrl,
        }
      );
      return { userId, userPictureUrl: response.data.userPicture };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  token: null,
  userDetails: {},
  loginError: null,
  signupError: null,
  isAuthenticated: false,
  profileImage: null,
  updateProfileStatus: "idle",
  updateProfileError: null,
  selectedFileType: null,
  selectedFolder: null,
  progressPercentage: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.userDetails = action.payload.userDetails;
      state.loginError = null;
      state.isAuthenticated = true;
    },
    loginFailure: (state, action) => {
      state.loginError = action.payload;
    },
    signup: (state, action) => {
      state.token = action.payload.token;
      state.userDetails = action.payload.userDetails;
      state.signupError = null;
      state.isAuthenticated = true;
    },
    signupFailure: (state, action) => {
      state.signupError = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.userDetails = null;
      state.isAuthenticated = false;
      state.profileImage = null;
      state.progressPercentage = 0;
    },
    setSelectedFileType: (state, action) => {
      state.selectedFileType = action.payload;
    },
    setSelectedFolder: (state, action) => {
      state.selectedFolder = action.payload;
    },
    updateProfileImage: (state, action) => {
      console.log(action.payload, "New Image");
      // const value = {
      //   ...state,
      //   profileImage: action.payload.secureUrl,
      // };
      // console.log(state);
      // localStorage.removeItem("userDetails");
      // localStorage.setItem("userDetails", value);
      state.profileImage = action.payload.secureUrl;
    },
    updateLocalUserProfile: (state, action) => {
      state.userDetails = { ...state.userDetails, ...action.payload };
    },
    updateProgress: (state, action) => {
      state.progressPercentage = action.payload.progressPercentage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.updateProfileStatus = "loading";
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        // console.log();
        state.updateProfileStatus = "succeeded";
        state.userDetails = action.payload;
        state.updateProfileError = null;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.updateProfileStatus = "failed";
        state.updateProfileError = action.payload;
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.profileImage = action.payload.userPictureUrl;
        if (
          state.userDetails &&
          state.userDetails.user_id === action.payload.userId
        ) {
          state.userDetails.userPicture = action.payload.userPictureUrl;
        }
      });
  },
});

export const {
  login,
  loginFailure,
  signup,
  signupFailure,
  logout,
  updateProfileImage,
  updateProgress,
  setSelectedFileType,
  setSelectedFolder,
} = userSlice.actions;

export default userSlice.reducer;
