import React from "react";
import "./App.css";
import { PersistGate } from "redux-persist/integration/react";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Community from "./pages/Community/Community";
import News from "./pages/News/News";
import Videos from "./pages/Videos/Videos";
import Contact from "./pages/Contact/Contact";
import Resources from "./pages/Resources/Resources";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "./components/Header/Header";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import ChatMessage from "./components/Assistant/ChatMessage";
import { DataProvider } from "./contextAPI/mycontext";
import AdminSignupForm from "./pages/Admin/Admin";
const App = () => {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/services", element: <Services /> },
    { path: "/community", element: <Community /> },
    { path: "/news", element: <News /> },
    { path: "/videos", element: <Videos /> },
    { path: "/contact", element: <Contact /> },
    { path: "/resources", element: <Resources /> },
    { path: "/chat", element: <ChatMessage /> },
    { path: "/user-Admin", element: <AdminSignupForm></AdminSignupForm> },
  ]);

  return (
    <div className="app">
      <DataProvider>
        <ToastContainer />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Header />
            <RouterProvider router={router} />
            <ToastContainer />
          </PersistGate>
        </Provider>
      </DataProvider>
    </div>
  );
};

export default App;
