import React from "react";
import S4 from "../../assets/SG 5.0 webview Tax & Business Services Banner.gif";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import Tax from "../../assets/tax.png";
import "./Business.css";

const Business = () => {
  return (
    <div className="Business">
      {/* <h1>SG Tax & Business Services</h1>
      <h2>Tax Preparation. Bookkeeping. Accounting.</h2> */}
      <div className="BusinessPic">
        <img src={S4} alt="" />
      </div>

      <div className="BusinessDataItem">
        {/* <div>
        <img src={Tax} alt="" />
        </div> */}
        <div>
          <p className="section-headings-center">
            "SG tax & business services works diligently to interpret and translate 
            your financial goals into workable solutions..."
          </p>
          <p>
            Sentry Group has provided Tax & Accounting Services for over 20
            years. Primarily, our Associates work directly with our client’s
            accountant. A few years ago, our Waterloo Office partnered with Lars
            Jorgensen of EJ Tax Services to handle clients looking for a second
            opinion or unhappy with a larger firm’s costs. 
          </p>
          <div className="read">
            <a href="/">
              Learn More <FaArrowUpRightFromSquare />
            </a>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="sg-tax-service-header">
<h1>Our Services</h1>
<img src={Tax} alt="" />
        </div>
        <div className="servicesList">
          <div>
            <h3>
              Corporate Consulting{" "}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </h3>
          </div>
          <div>
            <h3>
              Tax & Accounting{" "}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </h3>
          </div>
          <div>
            <h3>
              Business Services{" "}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </h3>
          </div>
          <div>
            <h3>
              Foundation Services{" "}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </h3>
          </div>
          <div>
            <h3>
              Tax Preparation{" "}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </h3>
          </div>
          <div>
            <h3>
              Succession Planning{" "}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
