import React, { useState } from "react";
import { ImSpinner9 } from "react-icons/im";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateProgress } from "../../redux/userSlice";
import { ipv4 } from "../../utils/config";
const ClientData = ({
  showClientData,
  clients,
  loading,
  selectedClient,
  handleClientClick,
  setSelectedClient,
  usedispatch,
  setLoading,
  setClients
}) => {
  const [clientData, setClientData] = useState(null);


  const userDetails = useSelector((state) => state.user.userDetails);
  const handleChange = (e) => {
    console.log(e.target);
    const { id, value, type, checked } = e.target;
    setSelectedClient((prevState) => ({
      ...prevState,
      [id]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? Number(value)
          : value,
    }));
  };
  const calculateProgress = (userDetails) => {
    // console.log(userDetails);
    if (!userDetails) return 0;

    const fields = [
      "UserProfile",
      "PortfolioManagement",
      "TaxAccount",
      "dataFilesUploads",
      "InsManagement",
      "BusServices",
      "WealthPlanning",
      "EstatePlanning",
      "FinancingMortgage",
    ];

    const totalValue = fields.reduce((sum, field) => {
      return sum + (Number(userDetails[`extraClientinfo_${field}`]) || 0);
    }, 0);

    const progress = Math.round((totalValue / 45) * 100);
    return Math.min(progress, 100);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await dispatch(updateUserProfile(formData));

      // if (response.meta.requestStatus === "fulfilled") {
      const backendResponse = await axios.put(
        `${ipv4}/update_user/${selectedClient.email}`,
        selectedClient
      );

      if (backendResponse.status === 200) {
        window.alert("Form submitted successfully!", { autoClose: 1000 });
        // dispatch(updateUserProfileLocally(formData));

        const progressPercentage = calculateProgress(selectedClient);
        usedispatch(updateProgress({ progressPercentage }));
      } else {
        throw new Error("Backend update failed");
      }
      // } else {
      //   throw new Error("Redux update failed");
      // }
    } catch (error) {
      console.error("Error occurred:", error);
      window.alert("An error occurred while updating your profile.", {
        autoClose: 3000,
      });
    }
  };

  const handleBackButtonClick = async () => {
    setSelectedClient(null);
    setLoading(true);
    console.log("Fetching clients for associateId:", userDetails.user_id); // Log associateId
    try {
      const response = await axios.post(`${ipv4}/getAssociatesClients`, {
        associateId: userDetails.user_id,
      });
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients", error);
    }
    setLoading(false);
  };

  if (!showClientData) return null;

  return (
    <>
      {/* Client Data Section */}
      <h1 className="User">Client Data</h1>
      {loading ? (
        <ImSpinner9
          className="spinner4"
          style={{ color: "#7878a4", fontSize: "18px" }}
        />
      ) : selectedClient ? (
        <div className="user-details-form">
          <div className="back-div">
            <button
              className="back-button"
              onClick={handleBackButtonClick}
            >
              Back to Client List
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-row" style={{ paddingTop: "20px" }}>
              <div className="form-group">
                <label htmlFor="firstName">First Name*</label>
                <input
                  type="text"
                  id="firstName"
                  className="input-field"
                  value={selectedClient.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name*</label>
                <input
                  type="text"
                  id="lastName"
                  className="input-field"
                  value={selectedClient.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  value={selectedClient.email}
                  className="input-field"
                  readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number*</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={selectedClient.phoneNumber}
                  className="input-field"
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                value={selectedClient.email}
                className="input-field"
                 onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Role*</label>
              <input
                type="text"
                id="role"
                value={selectedClient.role}
                 onChange={handleChange}
                className="input-field"
              />
            </div>
          </div> */}

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phoneNumber">Linkedin Link*</label>
                <input
                  type="text"
                  id="linkedinLink"
                  value={selectedClient.linkedinLink}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Book a call*</label>
                <input
                  id="bookACall"
                  value={selectedClient.bookACall}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="spouseFirstName">Spouse First Name*</label>
                <input
                  type="text"
                  id="spouseFirstName"
                  className="input-field"
                  value={selectedClient.spouseFirstName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="spouseLastName">Spouse Last Name*</label>
                <input
                  type="text"
                  id="spouseLastName"
                  className="input-field"
                  value={selectedClient.spouseLastName}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="Age">Your Age*</label>
                <input
                  type="number"
                  id="Age"
                  className="input-field"
                  value={selectedClient.Age}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="spouseAge">Spouse's Age*</label>
                <input
                  type="number"
                  id="spouseAge"
                  className="input-field"
                  value={selectedClient.spouseAge}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="children">Do you have children?</label>
                <select
                  type="text"
                  id="children"
                  className="input-field"
                  checked={selectedClient.children}
                  // onChange={handleChange}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="childrenAges">Children's Ages</label>
                <select
                    type="text"
                    id="childrenAges"
                    className="input-field"
                    checked={selectedClient.childrenAges}
                  >
                    <option value="1-18">1-18</option>
                    <option value="18>">{"18>"}</option>
                  </select>
              </div>
            </div>

            <h1>Residence</h1>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="primaryResidence.type">
                  Primary Residence*
                </label>
                <select
                    id="primaryResidence.type"
                    className="input-field"
                    checked={selectedClient.primaryResidence_type}
                  >
                    <option value="Rent">Rent</option>
                    <option value="Own">Own</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="secondaryResidence.type">
                  Secondary Residence*
                </label>
                <select
                    id="secondaryResidence.type"
                    className="input-field"
                    checked={selectedClient.secondaryResidence_type}
                    // onChange={handleChange}
                  >
                    <option value="Cottage">Cottage</option>
                    <option value="Apartment">Apartment</option>
                  </select>
              </div>
            </div>


            <div className="form-row">
              <div className="form-group">
                <label htmlFor="primaryResidence.value">
                  Primary Residence Value*
                </label>
                <input
                  type="number"
                  id="primaryResidence.value"
                  className="input-field"
                  value={selectedClient.primaryResidence}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="secondaryResidence.value">
                  Secondary Residence Value*
                </label>
                <input
                  type="number"
                  id="secondaryResidence.value"
                  className="input-field"
                  value={selectedClient.secondaryResidence}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="primaryResidence.mortgageAmountOrRent">
                  Primary Mortgage Amount (or Rent)*
                </label>
                <input
                  type="number"
                  id="primaryResidence.mortgageAmountOrRent"
                  className="input-field"
                  value={selectedClient.primaryResidence}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="secondaryResidence.mortgageAmount">
                  Secondary Mortgage Amount
                </label>
                <input
                  type="number"
                  id="secondaryResidence.mortgageAmount"
                  className="input-field"
                  value={selectedClient.secondaryResidence}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h1>Employment Information</h1>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="employmentInfo.status">
                  Employment Status*
                </label>
                <select
                    id="employmentInfo.status"
                    className="input-field"
                    checked={selectedClient.employmentInfo_status}
                    // onChange={handleChange}
                  >
                    <option value="Self-employed">Self-employed</option>
                    <option value="Employed">Employed</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="spouseEmploymentInfo.status">
                  Spouse Employment Status*
                </label>
                <select
                    id="spouseEmploymentInfo.status"
                    className="input-field"
                    checked={selectedClient.spouseEmploymentInfo_status}
                    // onChange={handleChange}
                  >
                    <option value="Self-employed">Self-employed</option>
                    <option value="Employed">Employed</option>
                  </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="employmentInfo.income">Annual Income*</label>
                <input
                  type="number"
                  id="employmentInfo.income"
                  className="input-field"
                  value={selectedClient.employmentInfo}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="spouseEmploymentInfo.income">
                  Spouse Annual Income*
                </label>
                <input
                  type="number"
                  id="spouseEmploymentInfo.income"
                  className="input-field"
                  value={selectedClient.spouseEmploymentInfo}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="employmentInfo.otherIncome">
                  Other Income*
                </label>
                <input
                  type="number"
                  id="employmentInfo.otherIncome"
                  className="input-field"
                  value={selectedClient.employmentInfo}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="spouseEmploymentInfo.otherIncome">
                  Spouse Other Income*
                </label>
                <input
                  type="number"
                  id="spouseEmploymentInfo.otherIncome"
                  className="input-field"
                  value={selectedClient.spouseEmploymentInfo}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h1>
              Please indicate which of the following you are seeking information
              about:
            </h1>
            <div className="form-checkbox-group">
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.mortgagePlanning"
                  className="input-field"
                  value={selectedClient.financialGoals_mortgagePlanning}
                  onChange={handleChange}
                />
                <label
                  htmlFor="services.mortgagePlanning"
                  className="checkbox-label"
                >
                  Mortgage Planning
                </label>
              </div>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.secondMortgage"
                  className="input-field"
                  value={selectedClient.financialGoals_secondMortgage}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.secondMortgage"
                  className="checkbox-label"
                >
                  Second Mortgage
                </label>
              </div>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.businessConsulting"
                  className="input-field"
                  value={selectedClient.financialGoals_businessConsulting}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.businessConsulting"
                  className="checkbox-label"
                >
                  Business Consulting
                </label>
              </div>
            </div>

            <div className="form-checkbox-group">
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.businessTaxPlan"
                  className="input-field"
                  value={selectedClient.financialGoals_businessTaxPreparation}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.businessTaxPlan"
                  className="checkbox-label"
                >
                  Business Tax Plan
                </label>
              </div>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.personalTaxPlan"
                  className="input-field"
                  value={selectedClient.financialGoals_personalTaxPreparation}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.personalTaxPlan"
                  className="checkbox-label"
                >
                  Personal Tax Plan
                </label>
              </div>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.estateOpp"
                  className="input-field"
                  value={selectedClient.financialGoals_realEstateOpportunities}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.estateOpp"
                  className="checkbox-label"
                >
                  Estate Opportunities
                </label>
              </div>
            </div>

            <div className="form-checkbox-group">
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.debtElimination"
                  className="input-field"
                  value={selectedClient.financialGoals_debtElimination}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.debtElimination"
                  className="checkbox-label"
                >
                  Debt Elimination
                </label>
              </div>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.retirementPlan"
                  className="input-field"
                  value={selectedClient.financialGoals_retirementPlan}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.retirementPlan"
                  className="checkbox-label"
                >
                  Retirement Plan
                </label>
              </div>
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="financialGoals.succession"
                  className="input-field"
                  value={selectedClient.financialGoals_succession}
                  onChange={handleChange}
                />
                <label
                  htmlFor="financialGoals.succession"
                  className="checkbox-label"
                >
                  Succession
                </label>
              </div>
            </div>

            <h1>Financial Assets</h1>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="yourRSP">Your RRSP</label>
                <input
                  type="number"
                  id="financialAssets.rrsp"
                  className="input-field"
                  defaultValue={selectedClient.financialAssets_rrsp}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="spouseRSP">Spouse's RRSP</label>
                <input
                  type="number"
                  id="financialAssets.spouseRSP"
                  className="input-field"
                  defaultValue={selectedClient.financialAssets_spouseRrsp}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="lifeInsurance.faceAmount">Your TFSA</label>
                <input
                  type="number"
                  id="lifeInsurance.faceAmount"
                  className="input-field"
                  defaultValue={selectedClient.lifeInsurance_faceAmount}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lifeInsurance.spouseTFSA">Spouse's TFSA</label>
                <input
                  type="number"
                  id="lifeInsurance.spouseTFSA"
                  className="input-field"
                  defaultValue={selectedClient.lifeInsurance_spouseFaceAmount}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="financialAssets.nonRegistered">
                  Your Non-Registered
                </label>
                <input
                  type="number"
                  id="financialAssets.nonRegistered"
                  className="input-field"
                  defaultValue={selectedClient.financialAssets_nonRegistered}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="financialAssets.spouseNonRegistered">
                  Spouse's Non-Registered
                </label>
                <input
                  type="number"
                  id="financialAssets.spouseNonRegistered"
                  className="input-field"
                  defaultValue={
                    selectedClient.financialAssets_spouseNonRegistered
                  }
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="financialAssets.monthlySavings">
                  How much are you saving monthly toward your goals?
                </label>
                <input
                  type="number"
                  id="financialAssets.monthlySavings"
                  className="input-field"
                  defaultValue={selectedClient.financialAssets_monthlySavings}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h1>Pension</h1>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="pension">Do you have a Pension?</label>
                <select
                  type="text"
                  id="pension_hasPension"
                  className="input-field"
                  checked={selectedClient.pension_hasPension}
                  // onChange={handleChange}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="spousePension">Spouse's Pension</label>
                <select
                    id="spousePension"
                    className="input-field"
                    defaultValue={selectedClient.spousePension}
                    onChange={handleChange}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="fullPension">
                  At what age can you retire with full pension?
                </label>
                <select
                    id="fullPension"
                    className="input-field"
                    defaultValue={selectedClient.fullPension}
                    onChange={handleChange}
                  >
                       <option value="50">50</option>
                       <option value="60">60</option>
                  </select>

              </div>
              <div className="form-group">
                <label htmlFor="spouseFullPension">
                  At what age can your spouse retire with full pension?
                </label>
                <select
                    id="spouseFullPension"
                    className="input-field"
                    defaultValue={selectedClient.spouseFullPension}
                    onChange={handleChange}
                  >
                       <option value="50">50</option>
                       <option value="60">60</option>
                  </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="retire">
                  At what age do you want to retire?
                </label>
                <select
                    id="retire"
                    className="input-field"
                    defaultValue={selectedClient.retire}
                    onChange={handleChange}
                  >
                       <option value="50">50</option>
                       <option value="60">60</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="spouseRetire">
                  At what age does your spouse want to retire?
                </label>
                <select
                    id="spouseRetire"
                    className="input-field"
                    defaultValue={selectedClient.spouseRetire}
                    onChange={handleChange}
                  >
                       <option value="50">50</option>
                       <option value="60">60</option>
                  </select>
              </div>
            </div>

            <h1>Insurance</h1>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="primaryInsurance">Primary Insurance*</label>
                <select
                    id="primaryInsurance"
                    className="input-field"
                    defaultValue={selectedClient.primaryInsurance}
                    onChange={handleChange}
                  >
                     <option value="Life">Life</option>
                     <option value="Health">Health</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="secondaryInsurance">Secondary Insurance*</label>
                <select
                    id="secondaryInsurance"
                    className="input-field"
                    defaultValue={selectedClient.secondaryInsurance}
                    onChange={handleChange}
                  >
                     <option value="Life">Life</option>
                     <option value="Health">Health</option>
                  </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="insuranceAmount">Insurance Amount*</label>
                <input
                  type="number"
                  id="insuranceAmount"
                  className="input-field"
                  defaultValue={selectedClient.insuranceAmount}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="spouseInsuranceAmount">
                  Spouse Insurance Amount*
                </label>
                <input
                  type="number"
                  id="spouseInsuranceAmount"
                  className="input-field"
                  defaultValue={selectedClient.spouseInsuranceAmount}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h1>Client Self-Graded Scorecard</h1>

            <div className="form-row" id="form-row-6">
              <div className="form-group">
                <label htmlFor="extraClientinfo_UserProfile">
                  User Profile
                </label>
                <select
                    id="extraClientinfo_UserProfile"
                    className="input-field"
                    defaultValue={selectedClient.extraClientinfo_UserProfile}
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="extraClientinfo_PortfolioManagement">
                  Portfolio Management
                </label>
                <select
                    id="extraClientinfo_PortfolioManagement"
                    className="input-field"
                    defaultValue={
                      selectedClient.extraClientinfo_PortfolioManagement
                    }
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="extraClientinfo_TaxAccount">
                  Tax & Accounting
                </label>
                <select
                    id="extraClientinfo_TaxAccount"
                    className="input-field"
                    defaultValue={selectedClient.extraClientinfo_TaxAccount}
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="extraClientinfo_dataFilesUploads">
                  Data Files & Uploads
                </label>
                <select
                    id="extraClientinfo_dataFilesUploads"
                    className="input-field"
                    defaultValue={
                      selectedClient.extraClientinfo_dataFilesUploads
                    }
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="extraClientinfo_InsManagement">
                  Insurance Management
                </label>
                <select
                    id="extraClientinfo_InsManagement"
                    className="input-field"
                    defaultValue={selectedClient.extraClientinfo_InsManagement}
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="extraClientinfo_BusServices">
                  Business Services
                </label>
                <select
                    id="extraClientinfo_BusServices"
                    className="input-field"
                    defaultValue={selectedClient.extraClientinfo_BusServices}
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="extraClientinfo_WealthPlanning">
                  Wealth Planning
                </label>
                <select
                    id="extraClientinfo_WealthPlanning"
                    className="input-field"
                    defaultValue={selectedClient.extraClientinfo_WealthPlanning}
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
              <div className="form-group">
                <label htmlFor="extraClientinfo_EstatePlanning">
                  Estate Planning
                </label>
                <select
                    id="extraClientinfo_EstatePlanning"
                    className="input-field"
                    defaultValue={selectedClient.extraClientinfo_EstatePlanning}
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>

              <div className="form-group">
                <label htmlFor="extraClientinfo_FinancingMortgage">
                  Financing & Mortgage
                </label>
                <select
                    id="extraClientinfo_FinancingMortgage"
                    className="input-field"
                    defaultValue={
                      selectedClient.extraClientinfo_FinancingMortgage
                    }
                    onChange={handleChange}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
              </div>
            </div>

            <h1>Wills / Power of Attorney</h1>

            <p>
              Sentry Group is committed to protecting and respecting your
              privacy, and we’ll only use your personal information to
              administer your account and to provide the products and services
              you requested from us. From time to time, we would like to contact
              you about our products and services, as well as other content that
              may be of interest to you. For more information please read our
              privacy policy. https://sentrygroup.ca/privacy
            </p>

            <div className="form-checkbox-group2">
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="consent_storeAndProcess"
                  className="input-field"
                  checked={selectedClient.consent_storeAndProcess}
                  
                />
                <label
                  htmlFor="dataProcessingConsent"
                  className="checkbox-label"
                >
                  I agree to allow Sentry Group to store and process my personal
                  data.*
                </label>
              </div>
            </div>
            <div className="form-checkbox-group2">
              <div className="form-checkbox">
                <input
                  type="checkbox"
                  id="consent_agreeTerms"
                  className="input-field"
                  checked={selectedClient.consent_agreeTerms}
                 
                />
                <label htmlFor="termsAgreement" className="checkbox-label">
                  I have reviewed, understood, and agreed to the Terms, Privacy
                  Policy and Relationship Disclosure Information
                </label>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="client-grid">
          {clients.map((client) => (
            <div
              key={client.id}
              className="client-box"
              onClick={() => handleClientClick(client)}
            >
              <div className="client-role">{client.role}</div>
              <div className="client-name">
                {client.firstName} {client.lastName}
              </div>
              <div className="hover-text">Click to see more details</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ClientData;