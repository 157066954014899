import React, { useState } from "react";
import { useSelector } from "react-redux";
import Weather from "../../components/Weather/Weather";
import Trending from "../../components/Trending/Trending";
import Price from "../../components/Price/Price";
import Login from "../../components/Login/Login";
import Signup from "../../components/SignUp/SignUp";
import OurPeople from "../../components/OurPeople/OurPeople";
import OurSolution from "../../components/OurSolution/OurSolution";
import Gas from "../../components/Gas/Gas";
import Exchange from "../../components/Exchange/Exchange";
import Account from "../../components/Account/Account";
import Review from "../../components/Review/Review";
import NewsData from "../../components/NewsData/NewsData";
import "./New.css";
import Assistant from "../../components/Assistant/Assistant";

const News = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [showSignup, setShowSignup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);

  const handleSignupClick = () => {
    setShowSignup(true);
  };

  const handleLoginClick = () => {
    setShowSignup(false);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleSignupSuccess = () => {
    setIsLoggedIn(true);
    setShowSignup(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <div className="home">
      <div className="side1">
        <Weather />
        <Trending />
        <Price />
        {/* <Gas /> */}
        <Exchange />
      </div>
      <div className="side2">
        {isLoggedIn ? <NewsData /> : <NewsData />}
        <div className="below-div">
        <Assistant />
        </div>
      </div>
      <div className="side3">
      <div className="logged-in">
          {isLoggedIn ? (
            <div className="logged-in__container">
            <Account onLogout={handleLogout} />
            <Review />
          </div>
          ) : showSignup ? (
            <Signup
              onLoginClick={handleLoginClick}
              onSignupSuccess={handleSignupSuccess}
            />
          ) : (
            <Login
              onSignupClick={handleSignupClick}
              onLoginSuccess={handleLoginSuccess}
            />
          )}
        </div>
        <OurPeople />
        <OurSolution />
      </div>
    </div>
  );
};

export default News;
