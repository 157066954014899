import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ipv4 } from "../../utils/config";
import "./Otp.css";
import "../ForgotPassword/ForgotPassword.css";

const Otp = ({ email, onLoginClick }) => {
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "otp") {
      setOtp(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Verify OTP first
        // Proceed to reset password
        const resetResponse = await axios.post(ipv4 + "/reset-password", {
          otp,
          password,
        });
        console.log(resetResponse);
        if (resetResponse.status === 200) {
          toast.success("Password reset successfully.");
        } else {
          toast.error("Failed to reset password. Please try again.");
        
      }
    } catch (error) {
      console.error("Error verifying OTP or resetting password:", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Otp">
      <h1>OTP</h1>
      <hr />
      <form onSubmit={handleSubmit}>
        <div>Enter your OTP</div>
        <input
          type="text"
          name="otp"
          value={otp}
          onChange={handleChange}
          required
          disabled={loading}
        />
        <div>Enter your new password</div>
        <input
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
          required
          disabled={loading}
        />
        <div className="login-btn">
          <div className="login" onClick={handleSubmit} disabled={loading}>
            {loading ? "Verifying..." : "Reset Password"}
          </div>
          <div className="signup" onClick={onLoginClick}>
            Login
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Otp;
