import React, { useEffect, useState, useContext } from "react";
import ProfileImg from "../../assets/profile.png";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfileImage,
  updateUserProfile,
  updateProgress,
  updateUserProfileLocally,
} from "../../redux/userSlice";
// import { calculateProgress } from "../../redux/utils";
import { toast, ToastContainer } from "react-toastify";
import { ImSpinner9 } from "react-icons/im";
import { FaPlus, FaTrash } from "react-icons/fa6";
import "react-toastify/dist/ReactToastify.css";
import "./Profile.css";
import { ipv4 } from "../../utils/config";
import axios from "axios";
import { DataContext } from "../../contextAPI/mycontext";
import { updateProfImage } from "../../redux/utils";
import CareLeft from "../../assets/CareLeft.png";
import UserData from "./UserData";
import ClientData from "./ClientData";

const Profile = () => {
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(ProfileImg);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [userPictureUrl, setUserPictureUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { Logindata, setLoginData } = useContext(DataContext);

  const [showUserData, setShowUserData] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [showClientData, setShowClientData] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  //console.log(Logindata);
  const [selectedImage, setSelectedImage] = useState(
    profileImage || ProfileImg
  );
  const [formData, setFormData] = useState({
    firstName: userDetails.firstName || "",
    lastName: userDetails.lastName || "",
    email: userDetails.email || "",
    phoneNumber: userDetails.phoneNumber || "",
    PrimaryAssociates: userDetails.PrimaryAssociates || "",
    linkedinLink: userDetails.linkedinLink || "",
    bookACall: userDetails.bookACall || "",
    spouseFirstName: userDetails.spouseFirstName || "",
    spouseLastName: userDetails.spouseLastName || "",
    Age: userDetails.Age || "",
    spouseAge: userDetails.spouseAge || "",
    children: userDetails.children || false,
    childrenAges: userDetails.childrenAges || "",

    primaryResidence_type: userDetails.primaryResidence_type || "",
    primaryResidence_value: userDetails.primaryResidence_value || 0,
    primaryResidence_mortgageAmountOrRent:
      userDetails.primaryResidence_mortgageAmountOrRent || 0,

    secondaryResidence_type: userDetails.secondaryResidence_type || "",
    secondaryResidence_value: userDetails.secondaryResidence_value || 0,
    secondaryResidence_mortgageAmount:
      userDetails.secondaryResidence_mortgageAmount || 0,

    employmentInfo_status: userDetails.employmentInfo_status || "Employed",
    employmentInfo_income: userDetails.employmentInfo_income || 0,
    employmentInfo_otherIncome: userDetails.employmentInfo_otherIncome || 0,

    spouseEmploymentInfo_status:
      userDetails.spouseEmploymentInfo_status || "Employed",
    spouseEmploymentInfo_income: userDetails.spouseEmploymentInfo_income || 0,
    spouseEmploymentInfo_otherIncome:
      userDetails.spouseEmploymentInfo_otherIncome || 0,

    financialGoals_mortgagePlanning:
      userDetails.financialGoals_mortgagePlanning || false,
    financialGoals_secondMortgage:
      userDetails.financialGoals_secondMortgage || false,
    financialGoals_businessConsulting:
      userDetails.financialGoals_businessConsulting || false,
    financialGoals_businessTaxPreparation:
      userDetails.financialGoals_businessTaxPreparation || false,
    financialGoals_personalTaxPreparation:
      userDetails.financialGoals_personalTaxPreparation || false,
    financialGoals_realEstateOpportunities:
      userDetails.financialGoals_realEstateOpportunities || false,
    financialGoals_debtElimination:
      userDetails.financialGoals_debtElimination || false,
    financialGoals_retirementPlan:
      userDetails.financialGoals_retirementPlan || false,
    financialGoals_succession: userDetails.financialGoals_succession || false,

    financialAssets_rrsp: userDetails.financialAssets_rrsp || 0,
    financialAssets_spouseRrsp: userDetails.financialAssets_spouseRrsp || 0,
    financialAssets_tfsa: userDetails.financialAssets_tfsa || 0,
    financialAssets_spouseTfsa: userDetails.financialAssets_spouseTfsa || 0,
    financialAssets_nonRegistered:
      userDetails.financialAssets_nonRegistered || 0,
    financialAssets_spouseNonRegistered:
      userDetails.financialAssets_spouseNonRegistered || 0,
    financialAssets_other: userDetails.financialAssets_other || 0,
    financialAssets_spouseOther: userDetails.financialAssets_spouseOther || 0,
    financialAssets_monthlySavings:
      userDetails.financialAssets_monthlySavings || 0,

    pension_hasPension: userDetails.pension_hasPension || false,
    pension_SpousehasPension: userDetails.pension_SpousehasPension || false,
    pension_retirementAge: userDetails.pension_retirementAge || 0,
    pension_PrefferedRetirementAge:
      userDetails.pension_PrefferedRetirementAge || 0,
    pension_SpousePrefferedRetirementAge:
      userDetails.pension_SpousePrefferedRetirementAge || 0,
    pension_spouseRetirementAge: userDetails.pension_spouseRetirementAge || 0,

    groupBenefits_hasGroupBenefits:
      userDetails.groupBenefits_hasGroupBenefits || false,
    groupBenefits_benefits: userDetails.groupBenefits_benefits || "",
    groupBenefits_spouseHasGroupBenefits:
      userDetails.groupBenefits_spouseHasGroupBenefits || false,
    groupBenefits_spouseBenefits:
      userDetails.groupBenefits_spouseBenefits || "",

    lifeInsurance_insured: userDetails.lifeInsurance_insured || false,
    lifeInsurance_type: userDetails.lifeInsurance_type || "",
    lifeInsurance_faceAmount: userDetails.lifeInsurance_faceAmount || 0,
    lifeInsurance_monthlyPremium: userDetails.lifeInsurance_monthlyPremium || 0,
    lifeInsurance_spouseInsured:
      userDetails.lifeInsurance_spouseInsured || false,
    lifeInsurance_spouseType: userDetails.lifeInsurance_spouseType || "",
    lifeInsurance_spouseFaceAmount:
      userDetails.lifeInsurance_spouseFaceAmount || 0,
    lifeInsurance_spouseMonthlyPremium:
      userDetails.lifeInsurance_spouseMonthlyPremium || 0,

    willsPowerOfAttorney_hasWill:
      userDetails.willsPowerOfAttorney_hasWill || false,
    willsPowerOfAttorney_hasPowerOfAttorney:
      userDetails.willsPowerOfAttorney_hasPowerOfAttorney || false,
    willsPowerOfAttorney_spouseHasWill:
      userDetails.willsPowerOfAttorney_spouseHasWill || false,
    willsPowerOfAttorney_spouseHasPowerOfAttorney:
      userDetails.willsPowerOfAttorney_spouseHasPowerOfAttorney || false,

    additionalInformation: userDetails.additionalInformation || "",

    extraClientinfo_UserProfile: userDetails.extraClientinfo_UserProfile || 0,
    extraClientinfo_PortfolioManagement:
      userDetails.extraClientinfo_PortfolioManagement || 0,
    extraClientinfo_TaxAccount: userDetails.extraClientinfo_TaxAccount || 0,
    extraClientinfo_dataFilesUploads:
      userDetails.extraClientinfo_dataFilesUploads || 0,
    extraClientinfo_InsManagement:
      userDetails.extraClientinfo_InsManagement || 0,
    extraClientinfo_BusServices: userDetails.extraClientinfo_BusServices || 0,
    extraClientinfo_WealthPlanning:
      userDetails.extraClientinfo_WealthPlanning || 0,
    extraClientinfo_EstatePlanning:
      userDetails.extraClientinfo_EstatePlanning || 0,
    extraClientinfo_FinancingMortgage:
      userDetails.extraClientinfo_FinancingMortgage || 0,
    extraClientinfo_consentstoreAndProcess:
      userDetails.extraClientinfo_consentstoreAndProcess || true,
    extraClientinfo_consentagreeTerms:
      userDetails.extraClientinfo_consentagreeTerms || true,
  });

  useEffect(() => {
    if (userDetails && userDetails.userPicture) {
      setProfileImage(userDetails.userPicture);
    } else {
      setProfileImage(null);
    }
  }, [userDetails]);

  useEffect(() => {
    console.log("User Details:", userDetails);
    console.log("Form Data:", formData);
    if (userDetails) {
      setFormData({
        firstName: userDetails.firstName || "",
        lastName: userDetails.lastName || "",
        email: userDetails.email || "",
        phoneNumber: userDetails.phoneNumber || "",
        PrimaryAssociates: userDetails.PrimaryAssociates || "",
        linkedinLink: userDetails.linkedinLink || "",
        bookACall: userDetails.bookACall || "",
        spouseFirstName: userDetails.spouseFirstName || "",
        spouseLastName: userDetails.spouseLastName || "",
        Age: userDetails.Age || "",
        spouseAge: userDetails.spouseAge || "",
        children: userDetails.children || false,
        childrenAges: userDetails.childrenAges || "",

        primaryResidence_type: userDetails.primaryResidence_type || "",
        primaryResidence_value: userDetails.primaryResidence_value || 0,
        primaryResidence_mortgageAmountOrRent:
          userDetails.primaryResidence_mortgageAmountOrRent || 0,

        secondaryResidence_type: userDetails.secondaryResidence_type || "",
        secondaryResidence_value: userDetails.secondaryResidence_value || 0,
        secondaryResidence_mortgageAmount:
          userDetails.secondaryResidence_mortgageAmount || 0,

        employmentInfo_status: userDetails.employmentInfo_status || "Employed",
        employmentInfo_income: userDetails.employmentInfo_income || 0,
        employmentInfo_otherIncome: userDetails.employmentInfo_otherIncome || 0,

        spouseEmploymentInfo_status:
          userDetails.spouseEmploymentInfo_status || "Employed",
        spouseEmploymentInfo_income:
          userDetails.spouseEmploymentInfo_income || 0,
        spouseEmploymentInfo_otherIncome:
          userDetails.spouseEmploymentInfo_otherIncome || 0,

        financialGoals_mortgagePlanning:
          userDetails.financialGoals_mortgagePlanning || false,
        financialGoals_secondMortgage:
          userDetails.financialGoals_secondMortgage || false,
        financialGoals_businessConsulting:
          userDetails.financialGoals_businessConsulting || false,
        financialGoals_businessTaxPreparation:
          userDetails.financialGoals_businessTaxPreparation || false,
        financialGoals_personalTaxPreparation:
          userDetails.financialGoals_personalTaxPreparation || false,
        financialGoals_realEstateOpportunities:
          userDetails.financialGoals_realEstateOpportunities || false,
        financialGoals_debtElimination:
          userDetails.financialGoals_debtElimination || false,
        financialGoals_retirementPlan:
          userDetails.financialGoals_retirementPlan || false,
        financialGoals_succession:
          userDetails.financialGoals_succession || false,

        financialAssets_rrsp: userDetails.financialAssets_rrsp || 0,
        financialAssets_spouseRrsp: userDetails.financialAssets_spouseRrsp || 0,
        financialAssets_tfsa: userDetails.financialAssets_tfsa || 0,
        financialAssets_spouseTfsa: userDetails.financialAssets_spouseTfsa || 0,
        financialAssets_nonRegistered:
          userDetails.financialAssets_nonRegistered || 0,
        financialAssets_spouseNonRegistered:
          userDetails.financialAssets_spouseNonRegistered || 0,
        financialAssets_other: userDetails.financialAssets_other || 0,
        financialAssets_spouseOther:
          userDetails.financialAssets_spouseOther || 0,
        financialAssets_monthlySavings:
          userDetails.financialAssets_monthlySavings || 0,

        pension_hasPension: userDetails.pension_hasPension || false,
        pension_retirementAge: userDetails.pension_retirementAge || 0,
        pension_PrefferedRetirementAge:
          userDetails.pension_PrefferedRetirementAge || 0,
        pension_SpousePrefferedRetirementAge:
          userDetails.pension_SpousePrefferedRetirementAge || 0,
        pension_spouseRetirementAge:
          userDetails.pension_spouseRetirementAge || 0,

        groupBenefits_hasGroupBenefits:
          userDetails.groupBenefits_hasGroupBenefits || false,
        groupBenefits_benefits: userDetails.groupBenefits_benefits || "",
        groupBenefits_spouseHasGroupBenefits:
          userDetails.groupBenefits_spouseHasGroupBenefits || false,
        groupBenefits_spouseBenefits:
          userDetails.groupBenefits_spouseBenefits || "",

        lifeInsurance_insured: userDetails.lifeInsurance_insured || false,
        lifeInsurance_type: userDetails.lifeInsurance_type || "",
        lifeInsurance_faceAmount: userDetails.lifeInsurance_faceAmount || 0,
        lifeInsurance_monthlyPremium:
          userDetails.lifeInsurance_monthlyPremium || 0,
        lifeInsurance_spouseInsured:
          userDetails.lifeInsurance_spouseInsured || false,
        lifeInsurance_spouseType: userDetails.lifeInsurance_spouseType || "",
        lifeInsurance_spouseFaceAmount:
          userDetails.lifeInsurance_spouseFaceAmount || 0,
        lifeInsurance_spouseMonthlyPremium:
          userDetails.lifeInsurance_spouseMonthlyPremium || 0,

        willsPowerOfAttorney_hasWill:
          userDetails.willsPowerOfAttorney_hasWill || false,
        willsPowerOfAttorney_hasPowerOfAttorney:
          userDetails.willsPowerOfAttorney_hasPowerOfAttorney || false,
        willsPowerOfAttorney_spouseHasWill:
          userDetails.willsPowerOfAttorney_spouseHasWill || false,
        willsPowerOfAttorney_spouseHasPowerOfAttorney:
          userDetails.willsPowerOfAttorney_spouseHasPowerOfAttorney || false,

        additionalInformation: userDetails.additionalInformation || "",

        extraClientinfo_UserProfile:
          userDetails.extraClientinfo_UserProfile || 0,
        extraClientinfo_PortfolioManagement:
          userDetails.extraClientinfo_PortfolioManagement || 0,
        extraClientinfo_TaxAccount: userDetails.extraClientinfo_TaxAccount || 0,
        extraClientinfo_dataFilesUploads:
          userDetails.extraClientinfo_dataFilesUploads || 0,
        extraClientinfo_InsManagement:
          userDetails.extraClientinfo_InsManagement || 0,
        extraClientinfo_BusServices:
          userDetails.extraClientinfo_BusServices || 0,
        extraClientinfo_WealthPlanning:
          userDetails.extraClientinfo_WealthPlanning || 0,
        extraClientinfo_EstatePlanning:
          userDetails.extraClientinfo_EstatePlanning || 0,
        extraClientinfo_FinancingMortgage:
          userDetails.extraClientinfo_FinancingMortgage || 0,
        extraClientinfo_consentstoreAndProcess:
          userDetails.extraClientinfo_consentstoreAndProcess || true,
        extraClientinfo_consentagreeTerms:
          userDetails.extraClientinfo_consentagreeTerms || true,
      });
    }
  }, [userDetails]);

  const handleSkipClick = (id) => {
    const targetDiv = document.getElementById(id);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFetchUserData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${ipv4}/getAllUserData`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
    setLoading(false);
  };

  const handleUserClick = (user) => {
    // console.log(user);
    
    setSelectedUser(user);
    const progressPercentage = calculateProgress(user);
    dispatch(updateProgress({ progressPercentage }));
  };

  const handleFetchClientData = async () => {
    setLoading(true);
    console.log("Fetching clients for associateId:", userDetails.user_id); // Log associateId
    try {
      const response = await axios.post(`${ipv4}/getAssociatesClients`, {
        associateId: userDetails.user_id,
      });
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients", error);
    }
    setLoading(false);
  };

  const handleClientClick = (client) => {
    setSelectedClient(client);
    const progressPercentage = calculateProgress(client);
    dispatch(updateProgress({ progressPercentage }));
  };

  const scrollToFormRow1 = () => {
    const targetDiv = document.getElementById("form-row-1");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToFormRow2 = () => {
    const targetDiv = document.getElementById("form-row-2");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToFormRow3 = () => {
    const targetDiv = document.getElementById("form-row-3");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToFormRow4 = () => {
    const targetDiv = document.getElementById("form-row-4");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToFormRow5 = () => {
    const targetDiv = document.getElementById("form-row-5");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const calculateProgress = (userDetails) => {
    // console.log(userDetails);
    if (!userDetails) return 0;

    const fields = [
      "UserProfile",
      "PortfolioManagement",
      "TaxAccount",
      "dataFilesUploads",
      "InsManagement",
      "BusServices",
      "WealthPlanning",
      "EstatePlanning",
      "FinancingMortgage",
    ];

    const totalValue = fields.reduce((sum, field) => {
      return sum + (Number(userDetails[`extraClientinfo_${field}`]) || 0);
    }, 0);

    const progress = Math.round((totalValue / 45) * 100);
    return Math.min(progress, 100);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await dispatch(updateUserProfile(formData));

      // if (response.meta.requestStatus === "fulfilled") {
      const backendResponse = await axios.put(
        `${ipv4}/update_user/${formData.email}`,
        formData
      );

      if (backendResponse.status === 200) {
        window.alert("Form submitted successfully!", { autoClose: 1000 });
        // dispatch(updateUserProfileLocally(formData));

        const progressPercentage = calculateProgress(formData);
        dispatch(updateProgress({ progressPercentage }));
      } else {
        throw new Error("Backend update failed");
      }
      // } else {
      //   throw new Error("Redux update failed");
      // }
    } catch (error) {
      console.error("Error occurred:", error);
      window.alert("An error occurred while updating your profile.", {
        autoClose: 3000,
      });
    }
  };

  const updateLocalProfilePicture = async (userId, userPictureUrl) => {
    console.log(userId, userPictureUrl);

    try {
      const response = await axios.put(
        `${ipv4}/users/${userId}/updateUserPicture`,
        {
          userPicture: userPictureUrl,
        }
      );

      if (response.status === 200) {
        console.log("User picture updated successfully:", response.data);
        const secureUrl = userPictureUrl.replace(/^http:/, "https:");
        setProfileImage(secureUrl);
        localStorage.removeItem("userPicture");
        localStorage.setItem("userPicture", secureUrl);
        const details = { ...userDetails, userPicture: secureUrl };
        localStorage.setItem("userDetails", JSON.stringify(details));
        dispatch(updateProfileImage({ userId, secureUrl }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          userPicture: secureUrl,
        }));

        return {
          userId: userId,
          userPictureUrl: userPictureUrl,
        }; // Return userId and userPictureUrl after successful update
      } else {
        console.error("Failed to update user picture");
        throw new Error("Failed to update user picture");
      }
    } catch (error) {
      console.error("Error updating user picture:", error);
      throw error; // Throw the error for handling in the caller function
    }
  };

  const handleUpload = async (event) => {
    setIsUploading(true);
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "vvd72kne"); // Update with your Cloudinary upload preset
      data.append("cloud_name", "duxz8dth9");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/duxz8dth9/image/upload",
          {
            method: "POST",
            body: data,
          }
        );
        const result = await response.json();
        if (result.secure_url) {
          const secureUrl = result.secure_url.replace(/^http:/, "https:");
          setProfileImage(secureUrl);
          await updateLocalProfilePicture(userDetails.user_id, secureUrl);
          // dispatch(updateProfImage(userDetails.user_id, secureUrl));
          // if (userDetails.userPicture) {
          //  const picUrl =  await updateProfImage(userDetails.user_id, secureUrl);
          // } else {
          //   await updateProfImage(userDetails.user_id, secureUrl);
          // }
        } else {
          console.error("Error: No secure_url returned from Cloudinary.");
        }
      } catch (error) {
        console.error("Error while uploading: ", error);
      } finally {
        setLoading(false);
        setIsUploading(false);
      }
    }
  };
  const deleteUserPicture = async (userId) => {
    try {
      const response = await axios.delete(
        `${ipv4}/users/${userId}/deleteUserPicture`
      );

      if (response.status === 200) {
        console.log("User picture deleted successfully:", response.data);
        localStorage.setItem("userPicture", JSON.stringify(null));
        const details = { ...userDetails, userPicture: null };
        localStorage.setItem("userDetails", JSON.stringify(details));
        dispatch(updateProfileImage({ userId, userPictureUrl: null }));
        return response.data; // Return the response data
      } else {
        console.error("Failed to delete user picture");
        throw new Error("Failed to delete user picture");
      }
    } catch (error) {
      console.error("Error deleting user picture:", error);
      throw error; // Throw the error for handling in the caller function
    }
  };

  const handleDeleteImage = async () => {
    setLoading(true);
    setIsUploading(true);
    setProfileImage(ProfileImg);
    try {
      await deleteUserPicture(userDetails.user_id);
    } catch (error) {
      console.error("Error deleting user picture:", error);
    }
    setLoading(false);
    setIsUploading(false);
  };

  // const handleChange = (e) => {
  //   const { id, value, type, checked } = e.target;
  //   setFormData((prevState) => {
  //     const newState = { ...prevState };
  //     const keys = id.split('.');
  //     let current = newState;
  //     for (let i = 0; i < keys.length - 1; i++) {
  //       if (!current[keys[i]]) current[keys[i]] = {};
  //       current = current[keys[i]];
  //     }
  //     current[keys[keys.length - 1]] = type === "checkbox" ? checked : value;
  //     return newState;
  //   });
  // };
  const handleChange = (e) => {
    console.log(e.target);
    const { id, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? Number(value)
          : value,
    }));
  };

  return (
    <div className="Profile">
      {!showUserData && !showClientData ? (
        <>
          <h1>Profile</h1>
          <h2>Discovery Data Collection</h2>
        </>
      ) : (
        <></>
      )}

      <div className="profile-header">
        {!showUserData && !showClientData ? (
          <div className="image-container">
            <div className="image-wrapper">
              {isUploading ? (
                <ImSpinner9
                  className="spinner4"
                  style={{ color: "#7878a4", fontSize: "18px" }}
                />
              ) : (
                <img
                  src={profileImage ? profileImage : ProfileImg}
                  alt="Profile"
                  className="user-image"
                  id="userPicture"
                />
              )}
              {profileImage && profileImage !== ProfileImg && (
                <button
                  className="delete-image-btn"
                  onClick={handleDeleteImage}
                >
                  <FaTrash size={15} style={{ color: "#6464f5" }} />
                </button>
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleUpload}
              style={{ display: "none" }}
              id="imageInput"
            />
            <label htmlFor="imageInput" className="change-image-btn">
              Change Image
            </label>
          </div>
        ) : (
          <></>
        )}

        {userRole === "Admin" && (
          <div className="dropdown">
            <button
              className="dropdown-button"
              onClick={() => {
                setShowUserData(!showUserData);
                if (!showUserData) handleFetchUserData();
              }}
            >
              {showUserData ? (
                <img src={CareLeft} alt={CareLeft} />
              ) : (
                "User Data"
              )}
            </button>
          </div>
        )}

        {userRole === "associate" && (
          <div className="dropdown">
            <button
              className="dropdown-button"
              onClick={() => {
                setShowClientData(!showClientData);
                setShowUserData(false);
                if (!showClientData) handleFetchClientData();
              }}
            >
              {showClientData ? (
                <img src={CareLeft} alt={CareLeft} />
              ) : (
                "Client Data"
              )}
            </button>
          </div>
        )}

        {userRole === "client" && null}
      </div>

      {!showUserData && !showClientData ? (
        <>
          <div className="form-row" style={{ paddingTop: "20px" }}>
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input
                type="text"
                id="firstName"
                className="input-field"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name*</label>
              <input
                type="text"
                id="lastName"
                className="input-field"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                value={userDetails.email}
                onChange={handleChange}
                className="input-field"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number*</label>
              <input
                type="tel"
                id="phoneNumber"
                value={userDetails.phoneNumber}
                onChange={handleChange}
                className="input-field"
                required
              />
            </div>
          </div>

          <div className="profile-form">
            <form onSubmit={handleSubmit}>
              {userRole === "associate" && (
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="spouseFirstName">Linkedin Link</label>
                    <input
                      type="text"
                      id="linkedinLink"
                      className="input-field"
                      value={formData.linkedinLink}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="spouseLastName">Book a call</label>
                    <input
                      type="text"
                      id="bookACall"
                      className="input-field"
                      value={formData.bookACall}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="spouseFirstName">Spouse First Name</label>
                  <input
                    type="text"
                    id="spouseFirstName"
                    className="input-field"
                    value={formData.spouseFirstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="spouseLastName">Spouse Last Name</label>
                  <input
                    type="text"
                    id="spouseLastName"
                    className="input-field"
                    value={formData.spouseLastName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="Age">Your Age</label>
                  <input
                    type="number"
                    id="Age"
                    className="input-field"
                    value={formData.Age}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="spouseAge">Spouse's Age</label>
                  <input
                    type="number"
                    id="spouseAge"
                    className="input-field"
                    value={formData.spouseAge}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="children">Do you have children?</label>
                  <div className="dropdown">
                    <select
                      type="text"
                      id="children"
                      className="input-field"
                      checked={formData.children}
                      onChange={handleChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="childrenAges">Children's Ages</label>
                  <div className="dropdown">
                    <select
                      type="text"
                      id="childrenAges"
                      className="input-field"
                      value={formData.childrenAges}
                      onChange={handleChange}
                      placeholder="1-18"
                    >
                      <option value="1-18">1-18</option>
                      <option value="18>">{"18>"}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="btn-flex">
                <div className="btn7">
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                </div>
                {userRole === "client" && (
                  <>
                    <div className="btn7 btn-clr">
                      <div className="submit-btn" onClick={scrollToFormRow1}>
                        Skip
                      </div>
                    </div>
                  </>
                )}
              </div>
              {userRole === "client" && (
                <>
                  <h1 id="form-row-1">Residence</h1>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="primaryResidence_type">
                        Primary Residence
                      </label>
                      <div className="dropdown">
                        <select
                          id="primaryResidence_type"
                          className="input-field"
                          value={formData.primaryResidence_type}
                          onChange={handleChange}
                        >
                          <option value="Rent">Rent</option>
                          <option value="Own">Own</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="secondaryResidence_type">
                        Secondary Residence
                      </label>
                      <div className="dropdown">
                        <select
                          id="secondaryResidence_type"
                          className="input-field"
                          value={formData.secondaryResidence_type || ""}
                          onChange={handleChange}
                        >
                          <option value="Cottage">Cottage</option>
                          <option value="Apartment">Apartment</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="primaryResidence_value">
                        Primary Residence Value
                      </label>
                      <input
                        type="number"
                        id="primaryResidence_value"
                        className="input-field"
                        value={formData.primaryResidence_value || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="secondaryResidence_value">
                        Secondary Residence Value
                      </label>
                      <input
                        type="number"
                        id="secondaryResidence_value"
                        className="input-field"
                        value={formData.secondaryResidence_value || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="primaryResidence_mortgageAmountOrRent">
                        Primary Mortgage Amount (or Rent)
                      </label>
                      <input
                        type="number"
                        id="primaryResidence_mortgageAmountOrRent"
                        className="input-field"
                        value={formData.primaryResidence_mortgageAmountOrRent}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="secondaryResidence_mortgageAmount">
                        Secondary Mortgage Amount
                      </label>
                      <input
                        type="number"
                        id="secondaryResidence_mortgageAmount"
                        className="input-field"
                        value={formData.secondaryResidence_mortgageAmount || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <h1>Employment Information</h1>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="employmentInfo_status">
                        Employment Status
                      </label>
                      <div className="dropdown">
                        <select
                          id="employmentInfo_status"
                          className="input-field"
                          value={formData.employmentInfo_status || ""}
                          onChange={handleChange}
                        >
                          <option value="Self-employed">Self-employed</option>
                          <option value="Employed">Employed</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="spouseEmploymentInfo_status">
                        Spouse Employment Status
                      </label>
                      <div className="dropdown">
                        <select
                          id="spouseEmploymentInfo_status"
                          className="input-field"
                          value={formData.spouseEmploymentInfo_status || ""}
                          onChange={handleChange}
                        >
                          <option value="Self-employed">Self-employed</option>
                          <option value="Employed">Employed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="employmentInfo_income">
                        Annual Income
                      </label>
                      <input
                        type="number"
                        id="employmentInfo_income"
                        className="input-field"
                        value={formData.employmentInfo_income || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="spouseEmploymentInfo_income">
                        Spouse Annual Income
                      </label>
                      <input
                        type="number"
                        id="spouseEmploymentInfo_income"
                        className="input-field"
                        value={formData.spouseEmploymentInfo_income || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="employmentInfo_otherIncome">
                        Other Income
                      </label>
                      <input
                        type="number"
                        id="employmentInfo_otherIncome"
                        className="input-field"
                        value={formData.employmentInfo_otherIncome || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="spouseEmploymentInfo_otherIncome">
                        Spouse Other Income
                      </label>
                      <input
                        type="number"
                        id="spouseEmploymentInfo_otherIncome"
                        className="input-field"
                        value={formData.spouseEmploymentInfo_otherIncome || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="btn-flex">
                    <div className="btn7">
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                    <div className="btn7 btn-clr">
                      <div className="submit-btn" onClick={scrollToFormRow2}>
                        Skip
                      </div>
                    </div>
                  </div>

                  <h1 id="form-row-2">
                    Please indicate which of the following you are seeking
                    information about:
                  </h1>
                  <div className="form-checkbox-group">
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_mortgagePlanning"
                        className="input-field"
                        checked={formData.financialGoals_mortgagePlanning}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="services.mortgagePlanning"
                        className="checkbox-label"
                      >
                        Mortgage Planning
                      </label>
                    </div>
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_secondMortgage"
                        className="input-field"
                        checked={formData.financialGoals_secondMortgage}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_secondMortgage"
                        className="checkbox-label"
                      >
                        Second Mortgage
                      </label>
                    </div>
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_businessConsulting"
                        className="input-field"
                        checked={formData.financialGoals_businessConsulting}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_businessConsulting"
                        className="checkbox-label"
                      >
                        Business Consulting
                      </label>
                    </div>
                  </div>

                  <div className="form-checkbox-group">
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_businessTaxPlan"
                        className="input-field"
                        value={formData.financialGoals_businessTaxPreparation}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_businessTaxPlan"
                        className="checkbox-label"
                      >
                        Business Tax Plan
                      </label>
                    </div>
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_personalTaxPlan"
                        className="input-field"
                        value={formData.financialGoals_personalTaxPreparation}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_personalTaxPlan"
                        className="checkbox-label"
                      >
                        Personal Tax Plan
                      </label>
                    </div>
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_estateOpp"
                        className="input-field"
                        value={formData.financialGoals_realEstateOpportunities}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_estateOpp"
                        className="checkbox-label"
                      >
                        Estate Opportunities
                      </label>
                    </div>
                  </div>

                  <div className="form-checkbox-group">
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_debtElimination"
                        className="input-field"
                        checked={formData.financialGoals_debtElimination}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_debtElimination"
                        className="checkbox-label"
                      >
                        Debt Elimination
                      </label>
                    </div>
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_retirementPlan"
                        className="input-field"
                        checked={formData.financialGoals_retirementPlan}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_retirementPlan"
                        className="checkbox-label"
                      >
                        Retirement Plan
                      </label>
                    </div>
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="financialGoals_succession"
                        className="input-field"
                        checked={formData.financialGoals_succession}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="financialGoals_succession"
                        className="checkbox-label"
                      >
                        Succession
                      </label>
                    </div>
                  </div>

                  <div className="btn-flex">
                    <div className="btn7">
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                    <div className="btn7 btn-clr">
                      <div className="submit-btn" onClick={scrollToFormRow3}>
                        Skip
                      </div>
                    </div>
                  </div>

                  <h1>Financial Assets</h1>
                  <div className="form-row" id="form-row-3">
                    <div className="form-group">
                      <label htmlFor="yourRSP">Your RRSP</label>
                      <input
                        type="text"
                        id="financialAssets_rrsp"
                        className="input-field"
                        defaultValue={formData?.financialAssets_rrsp || ""}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="spouseRSP">Spouse's RRSP</label>
                      <input
                        type="text"
                        id="financialAssets_spouseRSP"
                        className="input-field"
                        defaultValue={
                          formData?.financialAssets_spouseRrsp || ""
                        }
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="lifeInsurance_faceAmount">
                        Your TFSA
                      </label>
                      <input
                        type="text"
                        id="lifeInsurance_faceAmount"
                        className="input-field"
                        defaultValue={formData?.lifeInsurance_faceAmount || ""}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lifeInsurance_spouseTFSA">
                        Spouse's TFSA
                      </label>
                      <input
                        type="text"
                        id="lifeInsurance_spouseTFSA"
                        className="input-field"
                        defaultValue={
                          formData?.lifeInsurance_spouseFaceAmount || ""
                        }
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="financialAssets_nonRegistered">
                        Your Non-Registered
                      </label>
                      <input
                        type="text"
                        id="financialAssets_nonRegistered"
                        className="input-field"
                        defaultValue={
                          formData?.financialAssets_nonRegistered || ""
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="financialAssets_spouseNonRegistered">
                        Spouse's Non-Registered
                      </label>
                      <input
                        type="text"
                        id="financialAssets_spouseNonRegistered"
                        className="input-field"
                        defaultValue={
                          formData?.financialAssets_spouseNonRegistered || ""
                        }
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="financialAssets_monthlySavings">
                        How much are you saving monthly toward your goals?
                      </label>
                      <input
                        type="text"
                        id="financialAssets_monthlySavings"
                        className="input-field"
                        defaultValue={
                          formData?.financialAssets_monthlySavings || ""
                        }
                      />
                    </div>
                  </div>

                  <div className="btn-flex">
                    <div className="btn7">
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                    <div className="btn7 btn-clr">
                      <div className="submit-btn" onClick={scrollToFormRow4}>
                        Skip
                      </div>
                    </div>
                  </div>

                  <h1>Pension</h1>
                  <div className="form-row" id="form-row-4">
                    <div className="form-group">
                      <label htmlFor="pension_hasPension">
                        Do you have a Pension?
                      </label>
                      <div className="dropdown">
                        <select
                          id="pension_hasPension"
                          className="input-field"
                          defaultValue={formData?.pension_hasPension || ""}
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="pension_SpousehasPension">
                        Spouse's Pension
                      </label>
                      <div className="dropdown">
                        <select
                          id="pension_SpousehasPension"
                          className="input-field"
                          defaultValue={
                            formData?.pension_SpousehasPension || ""
                          }
                        >
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="pension_PrefferedRetirementAge">
                        At what age can you retire with full pension?
                      </label>
                      <div className="dropdown">
                        <select
                          id="pension_PrefferedRetirementAge"
                          className="input-field"
                          defaultValue={
                            formData?.pension_PrefferedRetirementAge || ""
                          }
                        >
                          <option value="50">50</option>
                          <option value="60">60</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="pension_PrefferedRetirementAge">
                        At what age can your spouse retire with full pension?
                      </label>
                      <div className="dropdown">
                        <select
                          id="pension_PrefferedRetirementAge"
                          className="input-field"
                          defaultValue={
                            formData?.pension_PrefferedRetirementAge || ""
                          }
                        >
                          <option value="50">50</option>
                          <option value="60">60</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="pension_retirementAge">
                        At what age do you want to retire?
                      </label>
                      <div className="dropdown">
                        <select
                          id="retirpension_retirementAge"
                          className="input-field"
                          defaultValue={formData?.pension_retirementAge || ""}
                        >
                          <option value="50">50</option>
                          <option value="60">60</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="pension_spouseRetirementAge">
                        At what age does your spouse want to retire?
                      </label>
                      <div className="dropdown">
                        <select
                          id="pension_spouseRetirementAge"
                          className="input-field"
                          defaultValue={
                            formData?.pension_spouseRetirementAge || ""
                          }
                        >
                          <option value="50">50</option>
                          <option value="60">60</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="btn-flex">
                    <div className="btn7">
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                    <div className="btn7 btn-clr">
                      <div className="submit-btn" onClick={scrollToFormRow5}>
                        Skip
                      </div>
                    </div>
                  </div>

                  <h1>Insurance</h1>
                  <div className="form-row" id="form-row-5">
                    <div className="form-group">
                      <label htmlFor="lifeInsurance_type">
                        Primary Insurance
                      </label>
                      <div className="dropdown">
                        <select
                          id="lifeInsurance_type"
                          className="input-field"
                          defaultValue={formData?.lifeInsurance_type || ""}
                        >
                          <option value="Life">Life</option>
                          <option value="Health">Health</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="secondaryInsurance">
                        Secondary Insurance
                      </label>
                      <div className="dropdown">
                        <select
                          id="secondaryInsurance"
                          className="input-field"
                          defaultValue={formData?.secondaryInsurance || ""}
                        >
                          <option value="Life">Life</option>
                          <option value="Health">Health</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="lifeInsurance_faceAmount">
                        Insurance Amount
                      </label>
                      <input
                        type="number"
                        id="lifeInsurance_faceAmount"
                        className="input-field"
                        defaultValue={formData?.lifeInsurance_faceAmount || ""}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lifeInsurance_spouseFaceAmount">
                        Spouse Insurance Amount
                      </label>
                      <input
                        type="number"
                        id="lifeInsurance_spouseFaceAmount"
                        className="input-field"
                        defaultValue={
                          formData?.lifeInsurance_spouseFaceAmount || ""
                        }
                      />
                    </div>
                  </div>

                  <div className="btn-flex">
                    <div className="btn7">
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                    <div className="btn7 btn-clr">
                      <div
                        className="submit-btn"
                        onClick={() => handleSkipClick("form-row-6")}
                      >
                        Skip
                      </div>
                    </div>
                  </div>

                  {/* extra fields */}
                  <h1>Client Self-Graded Scorecard</h1>
                  {/* <h3>(Check boxes if you wish to receive additional information)</h3> */}
                  <div className="form-row" id="form-row-6">
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_UserProfile">
                        User Profile
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_UserProfile"
                          className="input-field"
                          defaultValue={formData.extraClientinfo_UserProfile}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_PortfolioManagement">
                        Portfolio Management
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_PortfolioManagement"
                          className="input-field"
                          defaultValue={
                            formData.extraClientinfo_PortfolioManagement
                          }
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_TaxAccount">
                        Tax & Accounting
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_TaxAccount"
                          className="input-field"
                          defaultValue={formData.extraClientinfo_TaxAccount}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_dataFilesUploads">
                        Data Files & Uploads
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_dataFilesUploads"
                          className="input-field"
                          defaultValue={
                            formData.extraClientinfo_dataFilesUploads
                          }
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_InsManagement">
                        Insurance Management
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_InsManagement"
                          className="input-field"
                          defaultValue={formData.extraClientinfo_InsManagement}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_BusServices">
                        Business Services
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_BusServices"
                          className="input-field"
                          defaultValue={formData.extraClientinfo_BusServices}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_WealthPlanning">
                        Wealth Planning
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_WealthPlanning"
                          className="input-field"
                          defaultValue={formData.extraClientinfo_WealthPlanning}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="extraClientinfo_EstatePlanning">
                        Estate Planning
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_EstatePlanning"
                          className="input-field"
                          defaultValue={formData.extraClientinfo_EstatePlanning}
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="extraClientinfo_FinancingMortgage">
                        Financing & Mortgage
                      </label>
                      <div className="dropdown">
                        <select
                          id="extraClientinfo_FinancingMortgage"
                          className="input-field"
                          defaultValue={
                            formData.extraClientinfo_FinancingMortgage
                          }
                          onChange={handleChange}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="btn-flex">
                    <div className="btn7">
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                    <div className="btn7 btn-clr">
                      <div
                        className="submit-btn"
                        onClick={() => handleSkipClick("form-row-7")}
                      >
                        Skip
                      </div>
                    </div>
                  </div>

                  <h1>Wills / Power of Attorney</h1>

                  <p>
                    Sentry Group is committed to protecting and respecting your
                    privacy, and we’ll only use your personal information to
                    administer your account and to provide the products and
                    services you requested from us. From time to time, we would
                    like to contact you about our products and services, as well
                    as other content that may be of interest to you. For more
                    information please read our privacy
                    policy. https://sentrygroup.ca/privacy
                  </p>

                  <div className="form-checkbox-group2" id="form-row-7">
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="consent_storeAndProcess"
                        className="input-field"
                        checked={formData.consent_storeAndProcess}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="dataProcessingConsent"
                        className="checkbox-label"
                      >
                        I agree to allow Sentry Group to store and process my
                        personal data.
                      </label>
                    </div>
                  </div>
                  <div className="form-checkbox-group2">
                    <div className="form-checkbox">
                      <input
                        type="checkbox"
                        id="consent_agreeTerms"
                        className="input-field"
                        checked={formData.consent_agreeTerms}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="termsAgreement"
                        className="checkbox-label"
                      >
                        I have reviewed, understood, and agreed to the Terms,
                        Privacy Policy and Relationship Disclosure Information
                      </label>
                    </div>
                  </div>
                  <div className="btn7">
                    <button type="submit" className="submit-btn">
                      Submit
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </>
      ) : showUserData ? (
        <UserData
        showUserData={showUserData}
        loading={loading}
        selectedUser={selectedUser}
        users={users}
        handleUserClick={handleUserClick}
        setSelectedUser={setSelectedUser}
        usedispatch={dispatch}
        setLoading={setLoading}
        setUsers={setUsers}
      />
      ) : (
      <ClientData 
          showClientData={showClientData}
        loading={loading}
        selectedClient={selectedClient}
        clients={clients}
        handleClientClick={handleClientClick}
        setSelectedClient={setSelectedClient}
        usedispatch={dispatch}
        setLoading={setLoading}
        setClients={setClients}
      />
      )}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Profile;
