import React, { useState, useEffect } from "react";
import { IoChevronBackSharp, IoCloseSharp } from "react-icons/io5";
import { AiOutlineDownload, AiOutlineUpload, AiOutlineDelete } from "react-icons/ai";
import Pdf from "../../../assets/Pdf.png";
import Image from "../../../assets/image.png";
import "../RenderFile.css";
import { ipv4 } from "../../../utils/config";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ImSpinner9 } from "react-icons/im";
import { v4 as uuidv4 } from 'uuid';

const FolderFile = ({ onBackClick, folderId }) => {
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [uploadFile, setUploadFile] = useState(null);
    const userid = useSelector((state) => state.user.userDetails?.user_id);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewFile, setPreviewFile] = useState(null);
    useEffect(() => {
        fetchFolderFiles();
    }, [folderId, userid]);

    const fetchFolderFiles = async () => {
        try {
            const response = await axios.post(`${ipv4}/get-folders-files`, {
                folderId,
                userId: userid
            });
            setFilteredFiles(response.data.map(file => ({
                ...file,
                formattedDate: formatDate(file.addedAt)
            })));
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching folder files:", error);
            toast.error("Failed to fetch folder files");
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "2-digit" };
        return new Date(dateString).toLocaleDateString("en-US", options);
    };

    const handleDownload = (file) => {
        const blob = new Blob([new Uint8Array(file.data.data)], {
            type: file.fileType,
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.filename;
        a.click();
        URL.revokeObjectURL(url);
    };

    const truncateFileName = (fileName, maxLength = 14) => {
        if (!fileName) return '';
        if (fileName.length <= maxLength) return fileName;
        const extension = fileName.split('.').pop();
        const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
        const truncatedName = nameWithoutExtension.substring(0, maxLength - extension.length - 3);
        return `${truncatedName}...${extension}`;
    };

    const handleFileChange = (event) => {
        setUploadFile(event.target.files[0]);
    };
    const handlePreview = (file) => {
        setPreviewFile(file);
    };
    const closePreview = () => {
        setPreviewFile(null);
    };
    const arrayBufferToBase64 = (buffer) => {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
    const handleUpload = async () => {
        if (!uploadFile) {
            toast.error("No file selected");
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append("folderId", folderId);
        formData.append("fileData[fileId]", `${userid}_${Date.now()}_${uuidv4()}`);
        formData.append("fileData[filename]", uploadFile.name);
        formData.append("fileData[fileType]", uploadFile.type);
        formData.append("fileData[data]", uploadFile);
        formData.append("userId", userid);
    
        try {
            const response = await axios.post(`${ipv4}/add-folder-files`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.status === 200) {
                // toast.success("File uploaded successfully");
                setUploadFile(null); // Reset the uploadFile state
                fetchFolderFiles(); // Refresh the folder files after upload
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("Failed to upload file");
        } finally {
            setTimeout(() => setIsLoading(false), 300); // Add a small delay before hiding loader
        }
    };
    

    const handleFileSelect = (fileId) => {
        setSelectedFiles(prev =>
            prev.includes(fileId)
                ? prev.filter(id => id !== fileId)
                : [...prev, fileId]
        );
    };

    const handleDeleteFiles = async () => {
        if (selectedFiles.length === 0) {
            toast.warn("Please select files to delete");
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.post(`${ipv4}/delete-folder-file`, {
                folderId,
                fileIds: selectedFiles,
                userId: userid
            });

            if (response.status === 200) {
                toast.success("Files deleted successfully");
                setSelectedFiles([]);
                fetchFolderFiles(); // Refresh the file list
            }
        } catch (error) {
            console.error("Error deleting files:", error);
            toast.error("Failed to delete files: " + (error.response?.data?.message || error.message));
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="loader">
                <ImSpinner9 className="spinner" />
            </div>
        );
    }

    return (
        <div className="RenderFile">
            <div className="renderName">
                <div className="fileTypeTitle">
                    <h1>
                        <span onClick={onBackClick} className="backButton">
                            <IoChevronBackSharp size={14} />
                        </span>
                        Folder Files
                    </h1>
                </div>
                <div className="uploadSection">
                    {!selectedFiles.length ? (
                        <>
                            <input type="file" id="fileInput" onChange={handleFileChange} />
                            <label htmlFor="fileInput" className="customFileInput">
                                Choose File
                            </label>
                            <button onClick={handleUpload} className="uploadButton">
                                Upload
                            </button>
                            {uploadFile && (
                                <div className="fileName">
                                    {uploadFile.name}
                                </div>
                            )}
                        </>
                    ) : (
                        <button onClick={handleDeleteFiles} className="deleteButton">
                            <AiOutlineDelete size={15} /> Delete Selected
                        </button>
                    )}
                </div>
            </div>

            <div className="file-display">
                {filteredFiles.map((file, index) => (
                    <div key={index} className="filesData">
                        <div className="folderContainer" >
                            <input
                                type="checkbox"
                                checked={selectedFiles.includes(file.fileId)}
                                onChange={() => handleFileSelect(file.fileId)}
                                className="fileCheckbox"
                            />
                            <img
                                src={file.fileType && file.fileType.startsWith("image/") ? Image : Pdf}
                                alt="File Icon"
                                className="folderImg"
                                onClick={() => handlePreview(file)}
                            />
                            <button
                                className="downloadIcon"
                                onClick={() => handleDownload(file)}
                            >
                                <AiOutlineDownload size={15} />
                            </button>
                        </div>
                        <div className="fileInfo">
                            <h1>{truncateFileName(file.filename)}</h1>
                            <h3>{file.formattedDate}</h3>
                        </div>
                    </div>
                ))}
            </div>
            {previewFile && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={closePreview}>
                            <IoCloseSharp size={20} />
                        </button>
                        {previewFile.fileType.startsWith("image/") ? (
                            <img
                                src={`data:${previewFile.fileType};base64,${arrayBufferToBase64(
                                    previewFile.data.data
                                )}`}
                                alt={previewFile.filename}
                                style={{ maxWidth: "100%", maxHeight: "500px" }}
                            />
                        ) : previewFile.fileType === "application/pdf" ? (
                            <embed
                                src={`data:${previewFile.fileType};base64,${arrayBufferToBase64(
                                    previewFile.data.data
                                )}`}
                                type={previewFile.fileType}
                                style={{ width: "100%", height: "400px" }}
                            />
                        ) : (
                            <p>No preview available.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FolderFile;
