import React, { useRef, useState } from "react";
import "./OurSolution.css";
import GuyVideo from "../../assets/GuyVideo.mp4";
import PauseImage from "../../assets/Pause.png";

const OurSolution = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="OurSolution">
      <h1 className="pseudo-hr">Our Videos</h1>
      <hr />
      <div className="video-container">
        <video ref={videoRef} controls muted>
          <source src={GuyVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {isPlaying ? null : (
          <img
            src={PauseImage}
            alt="Pause"
            className="pause-image"
            onClick={handlePlay}
          />
        )}
      </div>
    </div>
  );
};

export default OurSolution;
