import React, { useState } from "react";
import "./Data.css";
import DataHeader from "../DataHeader/DataHeader";
import { useSelector } from "react-redux";
import RenderFile from "../DataFiles/RenderFile";

const Data = ({reloadData}) => {
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [showDataFiles, setShowDataFiles] = useState(true);
  const userId = useSelector((state) => state.user.userDetails?.user_id);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const [userData, setUserData] = useState(null);

  const hanldeSelectFolder=(fileType)=>{
    setSelectedFileType(fileType);
  }

  const handleFileTypeClick = (fileType, userDataFol) => {
    setSelectedFileType(fileType);
    setShowDataFiles(false);
    setUserData(userDataFol); // Update userData state
    // console.log(fileType);
    // toast.info(`Selected file type: ${fileType}`, {
    //   position: "top-center",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  };

  const handleBackClick = () => {
    setShowDataFiles(true);
    setSelectedFileType(null);
  };

  return (
    <div className="Data">
      {showDataFiles ? (
        <DataHeader
          uploadedFileType={selectedFileType}
          selectedFileType={selectedFileType}
          onFileTypeClick={handleFileTypeClick}
          userDataFol={userData} // Pass userData to DataHeader
          reloadData={reloadData}
        />
      ) : (
        <>
          <RenderFile fileType={selectedFileType} onBackClick={handleBackClick} userData={userData} />
        </>
      )}
    </div>
  );
};

export default Data;
