import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userSlice";
import ProfileImg from "../../assets/profile.png";
import Phone from "../../assets/PhoneCall.png";
import Envelope from "../../assets/Envelope.png";
import "./Account.css";
import { DataContext } from "../../contextAPI/mycontext";
import { ImSpinner9 } from 'react-icons/im';

const Account = ({ onLogout }) => {
  const { setLoginData, Logindata } = useContext(DataContext);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const profileImage = useSelector((state) => state.user.profileImage);

  const [profImg, setProfImg] = useState(ProfileImg);
  const [initialDetails, setInitialDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userDetails && userDetails.userPicture) {
      setProfImg(userDetails.userPicture);
    } else {
      setProfImg(ProfileImg); 
    }
  }, [userDetails]);

  useEffect(() => {
    if (profileImage) {
      setProfImg(profileImage);
      setIsLoading(false);
    }
  }, [profileImage]);

  useEffect(() => {
    if (userDetails && !initialDetails) {
      setInitialDetails(userDetails);
    }
  }, [userDetails, initialDetails]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.setItem('userDetails', JSON.stringify(null))
    if (typeof onLogout === "function") {
      onLogout();
    }
  };

  const displayedDetails = initialDetails || userDetails;

  return (
    <div className="Account">
      <div className="Price-tag pseudo-hr">
        <div>
          <h1>Account</h1>
        </div>
        <div className="logout">
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
      <hr />
      {displayedDetails ? (
        <div className="user-details">
          <div className="user-avatar">
            {isLoading ? (
              <ImSpinner9 className="spinner" style={{ color: "#7878a4", fontSize: "18px" }} />
            ) : (
              <img src={profImg?profImg:ProfileImg} alt="Profile" />
            )}
          </div>
          <div className="user-info">
            <h2>
              {displayedDetails.firstName} {displayedDetails.lastName}
            </h2>
            <h3>{displayedDetails.primaryAssociates}</h3>
            <div className="user-contact">
              <div className="contact-item">
                <div>
                  <img src={Phone} alt="Phone" />
                </div>
                <div>
                  <h1>{displayedDetails.phoneNumber}</h1>
                </div>
              </div>
              <div className="contact-item mail">
                <div>
                  <img src={Envelope} alt="Envelope" />
                </div>
                <div className="mail">
                  <h1>{displayedDetails.email}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No user data available.</p>
      )}
    </div>
  );
};

export default Account;
