export const calculateProgress = (userDetails) => {
    if (!userDetails || !userDetails.extraClientinfo) return 0;
  
    const extraClientInfo = userDetails.extraClientinfo;
    const fields = [
      'UserProfile',
      'PortfolioManagement',
      'TaxAccount',
      'dataFilesUploads',
      'InsManagement',
      'BusServices',
      'WealthPlanning',
      'EstatePlanning',
      'FinancingMortgage'
    ];
  
    const totalValue = fields.reduce((sum, field) => {
      return sum + (Number(extraClientInfo[field]) || 0);
    }, 0);
  
    const progress = Math.round((totalValue / 45) * 100);
    return Math.min(progress, 100); // Ensure progress doesn't exceed 100%
  };
  