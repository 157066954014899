import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ForgotPassword.css";
import { ipv4 } from "../../utils/config";
import Otp from "../Login/Otp";

const ForgotPassword = ({ onLoginClick }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [showOtpComponent, setShowOtpComponent] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(ipv4 + "/forgot-password", { email });
      if (response.status === 200) {
        window.alert("Password reset instructions sent to your email.");
        setOtpSent(true);
        setShowOtpComponent(true);
      } else {
        window.alert("Failed to send reset instructions. Please try again.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      window.alert("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  if (showOtpComponent) {
    return <Otp email={email} onLoginClick={onLoginClick} />;
  }
  return (
    <div className="ForgotPassword">
      <h1>Forgot Password</h1>
      <hr />
      <form onSubmit={handleSubmit}>
        <div>Email</div>
        <input
          type="email"
          value={email}
          onChange={handleChange}
          required
          disabled={loading}
        />
      </form>
      <div className="login-btn">
        <div className="login" onClick={handleSubmit} disabled={loading}>
          {loading ? "Loading..." : "Reset Password"}
        </div>
        <div className="signup" onClick={onLoginClick}>
          Login
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default ForgotPassword;
