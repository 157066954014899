import React from "react";
import "./Real.css";
import banner from "../../assets/SG 5.0 webview Landing Page Banner.gif";
const Real = () => {
  return (
    <div className="Real Data">
        <img src={banner} alt="" />
      <div className="para" style={{margin: 0, textAlign: "center", fontSize: "2rem", lineHeight: "2rem"}}>
        We provides solutions for individuals, small businesses and corporations
        using a truly transparent holistic approach.
      </div>
      <div>
        <a href="https://meetings.hubspot.com/marythibodeau1/45-min-free-consultation?uuid=69d63e6d-2f3a-442a-8dea-4e8d9956769f" className="btn">
          Book a Meeting or Video Call
        </a>
      </div>
    </div>
    // <div className="Real">
    //   <div className="headings">
    //     <h2>Real People.</h2>
    //     <h2>Real Advice.</h2>
    //     <h1>
    //       Real <span className="yellow-tag">Solutions.</span>
    //     </h1>
    //   </div>
    //   <div className="para">
    //     We provides solutions for individuals, small businesses and corporations
    //     using a truly transparent holistic approach.
    //   </div>
    //   <div>
    //     <a href="https://meetings.hubspot.com/marythibodeau1/45-min-free-consultation?uuid=69d63e6d-2f3a-442a-8dea-4e8d9956769f" className="btn">
    //       Book a Call
    //     </a>
    //   </div>
    //   <div className="meeting">
    //     <img src={Meeting} alt="" />
    //   </div>
    // </div>
  );
};

export default Real;
