import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { signup } from "../../redux/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ImSpinner9 } from "react-icons/im";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import Otp from "../Login/Otp";
import "./SignUp.css";
import { ipv4 } from "../../utils/config";
import SignupOTP from "./singupOTP";

const Signup = ({ onLoginClick, onSignupSuccess, SignupOTPComponent }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    primaryAssociates: "",
    password: "",
    role: "",
    otp: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showOtp, setShowOtp] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [associates, setAssociates] = useState([]);

  useEffect(() => {
    const fetchAssociates = async () => {
      try {
        const response = await axios.get(`${ipv4}/get-associates`);
        setAssociates(response.data);
      } catch (error) {
        console.error("Error fetching associates:", error);
        window.alert("Error fetching associates");
      }
    };
    fetchAssociates();
  }, []);
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phoneNumber, primaryAssociates, password, role } =
      formData;
    console.log(formData);
    const nameParts = name.trim().split(' ');
    if (!name || !email || !phoneNumber || !password || !role) {
      toast.error("Please enter all credentials");
      return;
    }

    if (nameParts.length < 2) {
      toast.error("Please enter Full Name");
      return;
    }

    if (role === "Client" && !primaryAssociates) {
      toast.error("Please select a primary associate");
      return;
    }
    if (password.length < 8) {
      window.alert("Password should be 8 characters long");
      return;
    }
    if (phoneNumber.length < 9) {
      window.alert("Enter a valid Phone Number");
      return;
    }
    if (!isValidEmail(email)) {
      window.alert("Please enter a valid email address");
      return;
    }
    setLoading(true);
    try {
      // Send OTP request
      const response = await axios.post(`${ipv4}/sendOtp`, { email, phoneNumber });

      if (response.status === 200) {
        window.alert("OTP sent successfully");
        setShowOtp(true);
      } else if (response.status === 409) {
        window.alert("User with provided email or phone number already exists");
      } else {
        window.alert(response.data.message || "An error occurred while sending OTP. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 409) {
          window.alert("User with provided email or phone number already exists", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          window.alert(data.message || "An error occurred during registration. Please try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        console.error("Error signing up:", error.message);
        window.alert("An error occurred during registration. Please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    const { name, email, phoneNumber, primaryAssociates, password, role, otp } =
      formData;
    // console.log(formData);
    if (!otp) {
      window.alert("Please enter OTP");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(ipv4 + "/signup", formData);

      if (response.status === 201) {
        const { user } = response.data;
        dispatch(signup({ token: user.token, userDetails: user }));
        localStorage.setItem("token", user.token);
        localStorage.setItem("userDetails", JSON.stringify(user));
        // toast.success("User registered successfully!", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        onSignupSuccess();
      } else {
        window.alert(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 405) {
          window.alert("Invalid or expired OTP", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (status === 409) {
          window.alert("User with provided email or phone number already exists", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          window.alert(data.message || "An error occurred during registration. Please try again.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        console.error("Error signing up:", error.message);
        window.alert("An error occurred during registration. Please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setLoading(false);
    }
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // if (loading) {
  //   return (
  //     <div className="loader">
  //       <ImSpinner9 className="spinner" />
  //     </div>
  //   );
  // }

  return (
    <div className="Signup">
      {showOtp ? (
        <>
          <h1>OTP Verification</h1>
          <hr />
          <form onSubmit={handleOtpSubmit}>
            <div>OTP</div>
            <input
              type="text"
              name="otp"
              value={formData.otp}
              onChange={handleChange}
              required
            />
          </form>
        </>
      ) : (
        <>
          <h1>Sign up</h1>
          <hr />
          <form onSubmit={handleSubmit}>
            <div>Name</div>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <div>Email</div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div>Phone Number</div>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            <div>Role</div>
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select role
              </option>
              {/* <option value="Admin">Admin</option>
              <option value="associate">Associate</option> */}
              <option value="client">Client</option>
            </select>
            {formData.role === "client" && (
              <>
                <div>Primary Associates</div>
                <select
                  name="primaryAssociates"
                  value={formData.primaryAssociates}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select primary associate
                  </option>
                  {associates.map((associate) => (
                    <option key={associate.user_id} value={associate.user_id}>
                      {associate.firstName} {associate.lastName}
                    </option>
                  ))}
                </select>
              </>
            )}
            <div>Password</div>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
            </div>

          </form>
        </>
      )}
      <div className="login-btn">
        <div className="create-account" onClick={showOtp ? handleSubmit : handleOtpSubmit}>
          {showOtp ? (
            loading ? (
              <ImSpinner9 className="spinner" style={{ color: "white", fontSize: "18px" }} />
            ) : (
              "Verify OTP"
            )
          ) : (
            loading ? (
              <ImSpinner9 className="spinner" style={{ color: "white", fontSize: "18px" }} />
            ) : (
              "Create Account"
            )
          )}
        </div>
        <div className="login" onClick={onLoginClick}>
          Login
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Signup;
