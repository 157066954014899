import Guy from "../../assets/Guy.png";

export const OurPeopleData = [
  {
    id: 1,
    img: Guy,
    name: "Douglas J Cerson CFP, FMA, CEA",
    role: "Founder and Chair",
    linkedinLink:'https://www.linkedin.com',
    emailLink: 'dougcerson@sentrygroup.ca',
    bookLink:"https://meetings.hubspot.com/marythibodeau1/45-min-free-consultation?uuid=69d63e6d-2f3a-442a-8dea-4e8d9956769f"
  },
 
];
