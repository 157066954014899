import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Exchange.css";
import CareLeft from "../../assets/CareLeft.png";
import CareRight from "../../assets/CareRight.png";

const Exchange = () => {
  const [exchangeData, setExchangeData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);

  const currencyPairs = ["CADUSD", "CADEUR", "CADMXN"];

  useEffect(() => {
    const fetchExchangeData = async () => {
      try {
        const promises = currencyPairs.map((pair) =>
          axios.get(
            `https://financialmodelingprep.com/api/v3/historical-price-full/${pair}?apikey=9rw5tKtOwqmTIGN4ocaSHaA0k045g8lT`
          )
        );

        //q5fNJzUp2F5CPj1HHFOtgXpEmq6UI1KX

        const responses = await Promise.all(promises);

        const formattedData = responses.map((response, index) => {
          const latestData = response.data.historical[0];
          return {
            id: index + 1,
            name: `${response.data.symbol}`,
            img: require("../../assets/bar.png"),
            price: latestData.close.toFixed(4),
            img2: require("../../assets/downarrow.png"),
            dp1: `${latestData.changePercent.toFixed(2)}%`,
          };
        });

        setExchangeData(formattedData);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
        setApiError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeData();
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === exchangeData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? exchangeData.length - 1 : prevIndex - 1
    );
  };

  if (loading) {
    return <div className="Exchange">Loading...</div>;
  }

  if (apiError) {
    return <div className="Exchange">Error fetching data</div>;
  }

  return (
    <div className="Exchange">
      <div className="Exchange-tag pseudo-hr">
        <div>
          <h1>Currency FX</h1>
        </div>
        {/* <div className="Arrow">
          <div onClick={handlePrev}>
            <img src={CareLeft} alt="Previous" />
          </div>
          <div onClick={handleNext}>
            <img src={CareRight} alt="Next" />
          </div>
        </div> */}
      </div>
      <hr />
      <div className="price-container2">
        {exchangeData.map((data, index) => {
          let pairLink = "#";
          if(data.name == "CADUSD") {
            pairLink = "https://finance.yahoo.com/quote/CADUSD=X/"
          } else if(data.name == "CADEUR") {
            pairLink = "https://ca.finance.yahoo.com/quote/CADEUR%3DX/"
          } else if(data.name == "CADMXN") {
            pairLink = "https://finance.yahoo.com/quote/CADMXN=X/"
          }
          return (
          <div className="ExchangeData" key={index}>
            <div>
              <a href={pairLink} target="_blank">{data.name}</a>
            </div>
            <div>
              <img src={data.img} alt={data.name} />
            </div>
            <div>
              <div className="data-price">{data.price}</div>
              <div className="data-dp1">
                <div>
                  <img src={data.img2} alt="" />
                </div>
                <div>{data.dp1}</div>
              </div>
            </div>
          </div>
        )})}
      </div>
    </div>
  );
};

export default Exchange;
