import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { signup } from "../../redux/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ImSpinner9 } from "react-icons/im";
import Google from "../../assets/google.png";
import Apple from "../../assets/apple.png";
import Otp from "../Login/Otp";
import "./SignUp.css";
import { ipv4 } from "../../utils/config";

const SignupOTP = ({ onLoginClick, onSignupSuccess,formData }) => {
  const dispatch = useDispatch();
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phoneNumber: "",
//     primaryAssociates: "",
//     password: "",
//     role: "",
//   });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phoneNumber, primaryAssociates, password, role } =
      formData;

    if (
      !name ||
      !email ||
      !phoneNumber ||
      !primaryAssociates ||
      !password ||
      !role
    ) {
      window.alert("Please enter all credentials");
      return;
    }
    if(password.length<8){
      window.alert("Password should be 8 characters long");
      return;
    }
    if(phoneNumber.length<9){
      window.alert("Enter a valid Phone Number");
      return;
    }
    if (!isValidEmail(email)) {
      window.alert("Please enter a valid email address");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        ipv4+"/signup",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        const { user } = response.data;
        dispatch(signup({ token: user.token, userDetails: user }));
        localStorage.setItem("token", user.token);
        localStorage.setItem("userDetails", JSON.stringify(user));
        window.alert("User registered successfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        onSignupSuccess();
      } 
      
      else {
        window.alert(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error signing up:", error);
      window.alert("An error occurred during registration. Please try again.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return (
      <div className="loader">
        <ImSpinner9 className="spinner" />
      </div>
    );
  }

  return (
    <div className="Signup">
      <h1>OTP Verification</h1>
      <hr />
      <form onSubmit={handleSignupSubmit}>
        <div>OTP</div>
        <input
          type="text"
          name="name"
          value={formData.name}
        //   onChange={handleChange}
          required
        />
        {/* <div>Email</div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <div>Phone Number</div>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />
        <div>Primary Associates</div>
        <input
          type="text"
          name="primaryAssociates"
          value={formData.primaryAssociates}
          onChange={handleChange}
          required
        />
        <div>Role</div>
        <select
          name="role"
          value={formData.role}
          onChange={handleChange}
          required
        >
          <option value="" disabled>
            Select role
          </option>
          <option value="admin">Admin</option>
          <option value="associate">Associate</option>
          <option value="client">Client</option>
        </select>
        <div>Password</div>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </span> */}
        {/* </div> */}
      </form>
      <div className="login-btn">
        <div className="create-account" onClick={handleSignupSubmit}>
          Create account
        </div>
        <div className="login" onClick={onLoginClick}>
          Login
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default SignupOTP;
