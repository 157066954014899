import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ImSpinner9 } from "react-icons/im";
import { setSelectedFileType, setSelectedFolder } from "../../redux/userSlice";
import { ipv4 } from "../../utils/config";
import Files from "../../assets/Files.png";
import "./Link.css";
import banner from "../../assets/SG 5.0 userview Wealth Planning Banner.gif";

import calculatorSoup from "../../assets/calculator-soup.png";

const Link = ({ onFileTypeClick, reloadData }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder, setSelectedFolderState] = useState(null);
  const userId = useSelector((state) => state.user.userDetails?.user_id);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        let response;
        if (userRole === "Admin") {
          response = await axios.get(`${ipv4}/get_Allfiles`);
        } else {
          response = await axios.post(`${ipv4}/getUserFilesById`, {
            user_id: userId,
          });
        }
        setIsLoading(false);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userRole, userId, reloadData]);

  const getFileCount = (category) => {
    if (!userData) return 0;

    if (Array.isArray(userData)) {
      return userData.reduce(
        (total, user) => total + (user[category]?.files?.length || 0),
        0
      );
    } else {
      return userData[category]?.files?.length || 0;
    }
  };


  const handleFolderSelect = (folderName) => {
    setSelectedFolderState(folderName);
    dispatch(setSelectedFolder(folderName));
    console.log("Folder selected:", folderName);
  };

  const handleFileTypeClick = (fileType) => {
    dispatch(setSelectedFileType(fileType));
    if (typeof onFileTypeClick === 'function') {
      onFileTypeClick(fileType, userData);
    } else {
      console.log("File type clicked:", fileType);
    }
  };

  const handleClick = () => {
    let clickTimer;
    return () => {
      if (clickTimer) {
        clearTimeout(clickTimer);
        handleFileTypeClick("LegalDocuments");
      } else {
        clickTimer = setTimeout(() => {
          handleFolderSelect("LegalDocuments");
          clickTimer = null;
        }, 300);
      }
    };
  };

  if (isLoading) {
    return (
      <div className="loader">
        <ImSpinner9 className="spinner" />
      </div>
    );
  }

  return (
    <div className="DataFiles">
      {/* <h1>Link</h1>
      <div className="FilesDataContainer">
        <div 
          className={`FileItem ${selectedFolder === "LegalDocuments" ? "selected-folder" : ""}`}
          onClick={handleClick()}
        >
          <div className="FileCount">{getFileCount("LegalDocuments")}</div>
          <div className="FileImageContainer">
            <div>
              <img src={Files} alt="Legal Documents" className="FileImage" />
            </div>
            <div className="FileName">
              <h1>Legal Documents</h1>
            </div>
          </div>
        </div>
      </div> */}
      <div className="details-container flex col gap-1">
        <img src={banner} alt="" />
        <div className="details-container-text">
          <h2>Take control of your financial future, revisit and reset 
            your plan today with a quick call or video session.
          </h2>
          <p>AI is powerful, but nothing replaces the human touch, combine the 
            best of both for success.
          </p>
          <a href="https://meetings.hubspot.com/marythibodeau1/45-min-free-consultation?uuid=69d63e6d-2f3a-442a-8dea-4e8d9956769f" className="btn">Get In Touch With Us Now</a>
        </div>
      </div>
      <div className="links-container">
        <div className="link-1 flex center">
        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/investment-calculator.php">Investment Calc</a>
        </div>
        <div className="link-2 flex center">
        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/savings-calculator.php">Savings Calc</a>
        </div>
        <div className="link-3 flex center">
        <a href="/resources" className="more-links-item">More Links &nbsp; <i className="fas fa-chevron-right"></i></a>
        </div>
        <div className="link-4 flex center">
        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/loan-calculator.php">Loan Calc</a>
        </div>
        <div className="link-5 flex center">
        <a target="_blank" href="https://www.calculatorsoup.com/calculators/financial/mortgage-calculator.php?do=pop">Mortgage Calc</a>
        </div>
      </div>
    </div>
  );
};

export default Link;