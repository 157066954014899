import React from "react";
import ServicesHeader from "../ServicesHeader/ServicesHeader";
import "./ServicesPage.css";
const ServicesPage = () => {
  return (
    <div className="ServicesPage">
      <ServicesHeader />
    </div>
  );
};

export default ServicesPage;
