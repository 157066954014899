import React, { useState, useEffect } from "react";
import "./Price.css";
import Bar from "../../assets/bar.png";
import DownArrow from "../../assets/downarrow.png";

const API_KEY = "goldapi-3pfpqlsly66ej8o-io"; // Replace with your GoldAPI key

const Price = () => {
  const [priceData, setPriceData] = useState([
    {
      id: 1,
      name: "GOLD/CAD",
      img: Bar,
      price: "Loading...",
      img2: DownArrow,
      dp1: "N/A",
    },
    {
      id: 2,
      name: "BTC/CAD",
      img: Bar,
      price: "Loading...",
      img2: DownArrow,
      dp1: "N/A",
    },
    {
      id: 3,
      name: "ETH/CAD",
      img: Bar,
      price: "Loading...",
      img2: DownArrow,
      dp1: "N/A",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGoldPrice = async () => {
      try {
        const response = await fetch("https://www.goldapi.io/api/XAU/CAD", {
          method: "GET",
          headers: {
            // "x-access-token": "goldapi-3png1slzsl6j9v-io",
            "x-access-token": "goldapi-9bi5g4sm1kdgmft-io",
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        setPriceData((prevData) =>
          prevData.map((data) =>
            data.name === "GOLD/CAD"
              ? {
                  ...data,
                  price: `$${result.price}`,
                  dp1: `${result.ch} (${result.chp}%)`,
                }
              : data
          )
        );
      } catch (error) {
        console.error("Error fetching Gold price:", error);
        setError("Error fetching data");
      }
    };

    const fetchCryptoPrices = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum&vs_currencies=cad"
        );
        const result = await response.json();
        setPriceData((prevData) =>
          prevData.map((data) => {
            if (data.name === "BTC/CAD") {
              return { ...data, price: `$${result.bitcoin.cad}` };
            } else if (data.name === "ETH/CAD") {
              return { ...data, price: `$${result.ethereum.cad}` };
            } else {
              return data;
            }
          })
        );
      } catch (error) {
        console.error("Error fetching Crypto prices:", error);
        setError("Error fetching data");
      }
    };

    fetchGoldPrice();
    fetchCryptoPrices();
    setLoading(false);
  }, []);

  console.log("prices: ", priceData);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="Price">
      <div className="Price-tag pseudo-hr">
        <h1>Commodity Prices</h1>
      </div>
      <hr />
      <div className="price-container">
        {priceData.map((data, index) => {
                    let pairLink = "#";
                    if(data.name == "GOLD/CAD") {
                      pairLink = "https://www.investing.com/currencies/xau-cad"
                    } else if(data.name == "BTC/CAD") {
                      pairLink = "https://ca.finance.yahoo.com/quote/BTC-CAD/"
                    } else if(data.name == "ETH/CAD") {
                      pairLink = "https://finance.yahoo.com/quote/ETH-CAD/"
                    }
          return (
          <div className="PriceData2" key={index}>
            <a href={pairLink} target="_blank">{data.name}</a>
            <img src={data.img} alt={data.name} />
            <div>
              <div className="data-price2">{!data.price.includes("undefined") ? data.price : "N/A"}</div>
              <div className="data-dp12">
                <img src={data.img2} alt="" />
                <div>{!data.dp1.includes("undefined") ? data.dp1 : "N/A"}</div>
              </div>
            </div>
          </div>
        )})}
      </div>
    </div>
  );
};

export default Price;
