import React, { useEffect, useState } from "react";
import DataFiles from "../DataFiles/DataFiles";
import Upload from "../Upload/Upload";
import RenderFile from "../DataFiles/RenderFile";
import Profile from "../Profile/Profile";
import Links from "../Link/Link";
import Portfolio from "../Portfolio/Portfolio";
import Tax from "../Tax/Tax";
import Insurance from "../Insurance/Insurance";
import Planning from "../Planing/Planing";
import Financing from "../Financing/Financing";
import "./DataHeader.css";
import BusinessServices from "../BusinessServices/BusinessServices";

const DataHeader = ({
  uploadedFileType,
  selectedFileType,
  onFileTypeClick,
  selectedFolder
}) => {
  const [activeTab, setActiveTab] = useState("DataFiles");

  useEffect(() => {
    if (uploadedFileType) {
      setActiveTab("RenderFile");
    }
  }, [uploadedFileType]);

  const renderComponent = () => {
    if (activeTab === "RenderFile" && uploadedFileType) {
      return <RenderFile fileType={uploadedFileType} />;
    }

    switch (activeTab) {
      case "DataFiles":
        return ""
      case "Profile":
        return <Profile />;
      case "Links":
        return <Links />;
      case "Portfolio":
        return <Portfolio />;
      case "Tax":
        return <Tax />;
      case "Insurance":
        return <Insurance />;
      case "Planning":
        return <Planning />;
      case "Financing":
        return <Financing />;
      case "Business":
          return <BusinessServices />;
      default:
        return null;
    }
  };

  return (
    <div className="DataHeaderContainer">
      <div className="DataHeader1">
        <ul>
          <li
            className={activeTab === "Profile" ? "active" : ""}
            onClick={() => setActiveTab("Profile")}
          >
           User Profile
          </li>
          <li
            className={activeTab === "DataFiles" ? "active" : ""}
            onClick={() => setActiveTab("DataFiles")}
          >
            Data Files
          </li>
          <li
            className={activeTab === "Links" ? "active" : ""}
            onClick={() => setActiveTab("Links")}
          >
            Wealth Planning
          </li>
          <li
            className={activeTab === "Portfolio" ? "active" : ""}
            onClick={() => setActiveTab("Portfolio")}
          >
            Portfolio Management
          </li>
          <li
            className={activeTab === "Insurance" ? "active" : ""}
            onClick={() => setActiveTab("Insurance")}
          >
            Insurance Management
          </li>
          <li
            className={activeTab === "Planning" ? "active" : ""}
            onClick={() => setActiveTab("Planning")}
          >
            Estate Planning
          </li>
          <li
            className={activeTab === "Tax" ? "active" : ""}
            onClick={() => setActiveTab("Tax")}
          >
            Tax & Accounting
          </li>
          <li
            className={activeTab === "Business" ? "active" : ""}
            onClick={() => setActiveTab("Business")}
          >
            Business Services
          </li>
          <li
            className={activeTab === "Financing" ? "active" : ""}
            onClick={() => setActiveTab("Financing")}
          >
            Financing & Mortgage
          </li>
        </ul>
      </div>
      <div className="DataContent">{renderComponent()}</div>
    </div>
  );
};

export default DataHeader;
