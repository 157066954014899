import React from "react";
import CommunityPic from "../../assets/SG 5.0 webview Community Banner.gif";
import "./CommunityPage.css";
import CP1 from "../../assets/CP1.png";
import CP2 from "../../assets/CP2.png";
import CP3 from "../../assets/CP3.png";
import AboutUs from "../AboutUs/AboutUs";

export const ContactDetails1 = [
  {
    id: 1,
    img: CP1,
    title: "Goderich to Guelph",
    para1:
      "Goderich to Guelph Rail Trail Inc. strives to enrich the quality of life for all people.",
    para2:
      "Our vision: To develop and maintain a continuous, safe and fully accessible G2G Rail Trail Experience that is 127 KM of linear, multi-use greenspace from the 401 corridor at Guelph, Ontario to the shores of Lake Huron at Goderich, Ontario. ",
  },
  {
    id: 2,
    img: CP2,
    title: "Legacy",
    para1:
      "We seek to provide meaningful financial support to other charitable organizations within our community whose aim is to improve the lives of those in need.",
  },
  {
    id: 3,
    img: CP3,
    title: "Habitat for Humanity",
    para1:
      "At Habitat for Humanity Guelph Wellington, we believe that a house is much more than the bricks-and-mortar. When we build houses with our volunteers, skilled tradespeople, families, partners and supporters. We are building a vibrant future for our entire community.",
  },
];

const CommunityPage = () => {
  return (
    <div className="CommunityPage">
      {/* <h1>Community</h1> */}
      <div className="CommunityPic">
        <img src={CommunityPic} alt="Contact" />
      </div>
      <h2 id="community-banner">Contribute. Support. Volunteer.</h2>
      <div className="CommunityData">
        {ContactDetails1.map((detail) => (
          <div key={detail.id} className="CommunityDataItem">
            <div>
              {" "}
              <img src={detail.img} alt={detail.title} />
            </div>
            <div>
              <h3>{detail.title}</h3>
              <p>{detail.para1}</p>
              <p>{detail.para2}</p>
            </div>
          </div>
        ))}
      </div>

      <hr />
      {/* <AboutUs /> */}
    </div>
  );
};

export default CommunityPage;
