import React,{useEffect,useState} from "react";
import ContactPic from "../../assets/SG 5.0 webview Contact Banner.gif";
import "./ContactPage.css";
import MapImg from "../../assets/maps3.png";
import LargeMap from '../../assets/maps-large.png';
import MapPinArea from "../../assets/MapPinArea.png";
import PhoneCall from "../../assets/PhoneCall.png";
import Envelope from "../../assets/Envelope.png";
import AboutUs from "../AboutUs/AboutUs";
// import GoogleMapReact from 'google-map-react';
export const ContactDetails1 = [
  {
    id: 1,
    img: MapPinArea,
    title: "19- 279 Weber Street North Waterloo, ON N2J 3H8",
  },
  {
    id: 2,
    img: PhoneCall,
    title: "1-866-880-9883 ext 131",
  },
  {
    id: 3,
    img: Envelope,
    title: "solution@sentrygroup.ca",
  },
];

export const ContactDetails2 = [
  {
    id: 1,
    img: MapPinArea,
    title: "224-871 Victoria Street N. Kitchener, ON N2B 3S4",
  },
  {
    id: 2,
    img: PhoneCall,
    title: "1-866-880-9883 ext 131",
  },
  {
    id: 3,
    img: Envelope,
    title: "solution@sentrygroup.ca",
  },
];

export const ContactDetails3 = [
  {
    id: 1,
    img: MapPinArea,
    title: "2225 Kingsway Drive Kitchener, ON N2C 1A2",
  },
  {
    id: 2,
    img: PhoneCall,
    title: "519-743-7238",
  },
  {
    id: 3,
    img: Envelope,
    title: "info@ejtax.ca",
  },
];

const ContactPage = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth > 27 * 96); // 30 inches * 96 pixels per inch
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);
  const defaultProps = {
    center: {
      lat: 43.463184555656,
      lng: -80.46569897725469
    },
    zoom: 11
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  return (
    <div className="ContactPage">
      {/* <h1>Contact</h1>
      <h2>Get in Touch: We’d Love to Hear from You.</h2> */}
      <div className="ContactPic">
        <img src={ContactPic} alt="Contact" />
      </div>
      <div className="Contact-Setting">
        <div>
          <div className="ContactData">
            <h3>Sentry Group | Motivating Success</h3>
            {ContactDetails1.map((detail) => (
              <div key={detail.id} className="ContactDetailItem">
                <img src={detail.img} alt={detail.title} />
                <p>{detail.title}</p>
              </div>
            ))}
          </div>
          <hr />
          <div className="ContactData">
            <h3>Sentry Group | Wealth Partners</h3>
            {ContactDetails2.map((detail) => (
              <div key={detail.id} className="ContactDetailItem">
                <img src={detail.img} alt={detail.title} />
                <p>{detail.title}</p>
              </div>
            ))}
          </div>
          <hr />
          <div className="ContactData">
            <h3>EJ Tax Services (SG Tax Prep)</h3>
            {ContactDetails3.map((detail) => (
              <div key={detail.id} className="ContactDetailItem">
                <img src={detail.img} alt={detail.title} />
                <p>{detail.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div  className='ContactImg'
        // style={{ height: '80vh', width: '70%',borderRadius:20 }}
        >
          {/* <img src={isLargeScreen ? LargeMap : MapImg} alt="" /> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11580.340147783809!2d-80.5221784!3d43.4796957!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882bf3cfa731f571%3A0x8e069cea153ea8a2!2sSentry%20Group%20%7C%20Wealth%20Partners!5e0!3m2!1sen!2ske!4v1727150371785!5m2!1sen!2ske" width="600" height="450" style={{border:0,}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          {/* <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            
            <AnyReactComponent
              lat={43.463184555656}
              lng={-80.46569897725469}
              text="My Marker"
            />
          </GoogleMapReact> */}
        </div>
      </div>
      <hr />
      {/* <AboutUs /> */}
    </div>
  );
};

export default ContactPage;
