import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Assistant.css";
import Chat from "../../assets/chat.png";
import { toast } from "react-toastify";
import ChatMessage from "./ChatMessage";
import { useNavigate } from "react-router-dom";

const Assistant = () => {
  const isAuthenticated = useSelector((state) => state.user.token !== null);
  const [showChatMessage, setShowChatMessage] = useState(false);
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();

  const handleChat = () => {
    if (isAuthenticated) {
      navigate("/chat", { state: { userInput } });
      // toast.success("Chat initiated successfully!");
    } else {
      window.alert("Please login first to chat with us.")
     // toast.error("Please login first to chat with us.");
    }
  };
  (function (d, t) {
    var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
    v.onload = function () {
      window.voiceflow.chat.load({
        verify: { projectID: '6667d382a7b6dc228b2386ad' },
        url: 'https://general-runtime.voiceflow.com',
        versionID: 'production',
        render: {
          mode: 'embedded',
          target: document.getElementById('sg'),
        },
        assistant: {
          stylesheet: "https://cdn.statically.io/gh/GuranReiSero/sentryCDN/main/voiceflow-30-10-24.css"
        },
        autostart: true,
      });
    }
    v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
  })(document, 'script');

  const handleCloseChatMessage = () => {
    setShowChatMessage(false);
    setUserInput("");
  };

  return (
    <div id="sg" className="Assistant">
      <h1>SG Assistant</h1>
      <hr />
      <div className="AssistantDetails">
        <img src={Chat} alt="Chat Icon" />
        <h3>Have a question?</h3>
        <h3>Chat with us.</h3>
        <textarea
          name="chatInput"
          id="chatInput"
          placeholder="Type your question here.."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
        ></textarea>
      </div>
      <div
        className={isAuthenticated ? "btn2" : "btn2-disabled"}
        onClick={handleChat}
      >
        <div className="chat">{isAuthenticated ? "Chat" : "Login to Chat"}</div>
      </div>
    </div>
  );
};

export default Assistant;
