import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";
import "./ChatMessage.css";

const ChatMessage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialMessage = location.state?.userInput || "";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState(initialMessage);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (initialMessage) {
      setMessages([{ text: initialMessage, sender: "user" }]);
      // triggerAIResponse();
    }
  }, [initialMessage]);

  const triggerAIResponse = () => {
    setIsTyping(true);
    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "We will respond to you in a bit.", sender: "ai" },
      ]);
      setIsTyping(false);
    }, 2000); 
  };

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: "user" }]);
      setInput("");
      triggerAIResponse();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleClose = () => {
    navigate("/");
  };

  return (
    <div className="ChatMessage">
      <div className="ChatHeader">
        <h2>SG Assistant</h2>
        <button className="CloseButton" onClick={handleClose}>
          <ImCross />
        </button>
      </div>
      <hr />
      <div className="MessageContainer">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={msg.sender === "user" ? "UserMessage" : "AiMessage"}
          >
            <div className="MessageContent">{msg.text}</div>
          </div>
        ))}
        {isTyping && (
          <div className="AiMessage">
            <div className="MessageContent typing">...</div>
          </div>
        )}
      </div>
      <div className="InputContainer">
        <textarea
          className="ChatInput"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
        />
        <button className="SendButton" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatMessage;
