import React, { createContext, useState,useEffect } from "react";


// Create a context
export const DataContext = createContext();

// Create a provider component
export const DataProvider = ({ children }) => {

  const [Logindata, setLoginData] = useState({});
 
  // const socket = new WebSocket(socketIpv4);
  // const { socketx,setSocketx } = useContext(DataContext);
  // useEffect(() => {
  //   setSocketx(socket);
  //  }, []);
 
  return (
    <DataContext.Provider value={{ Logindata, setLoginData }}>
      {children}
    </DataContext.Provider>
  );
};
