import Files from "../../assets/Files.png";
import FirstAidKit from "../../assets/FirstAidKit.png";
import BuildingOffice from "../../assets/BuildingOffice.png";
import Car from "../../assets/Car.png";
import ChartLine from "../../assets/ChartLine.png";
import Currency from "../../assets/CurrencyCircleDollar.png";
import Graduation from "../../assets/GraduationCap.png";
import Guitar from "../../assets/Guitar.png";
import Hand from "../../assets/HandCoins.png";
import Identification from "../../assets/IdentificationCard.png";
import List from "../../assets/ListChecks.png";
import Umbrella from "../../assets/Umbrella.png";
import Three from "../../assets/UsersThree.png";
import Other from '../../assets/Folder.png'

export const FilesData = [
  {
    id: 4,
    name: "Personal Planning",
    imgsrc: Hand,
    num: 0,
    type:"PersonalFinance"
  },
  {
    id: 5,
    name: "Investments",
    imgsrc: ChartLine,
    num: 0,
    type:"Investments"
  },
  {
    id: 2,
    name: "Insurance",
    imgsrc: Umbrella,
    num: 0,
    type:"InsurancePolicies"
  },
  {
    id: 1,
    name: "Estate Planning",
    imgsrc: Files,
    num: 0,
    type:"LegalDocuments"
  },
  {
    id: 7,
    name: "Taxes & Accounting",
    imgsrc: Currency,
    num: 0,
    type:"Taxes"
  },
  {
    id: 8,
    name: "Business & Corporate",
    imgsrc: Three,
    num: 0,
    type:"ServiceAccountMembership"
  },
  {
    id: 3,
    name: "Financing & Mortgage",
    imgsrc: Currency,
    num: 0,
    type:"Mortgage"
  },
  {
    id: 6,
    name: "Real Estate",
    imgsrc: BuildingOffice,
    num: 0,
    type:"RealEstate"
  },
  {
    id: 10,
    name: "Vehicles",
    imgsrc: Car,
    num: 0,
    type:"VehicleTransportation"
  },
  {
    id: 9,
    name: "Health & Medical",
    imgsrc: FirstAidKit,
    num: 0,
    type:"HealthMedical"
  },
  // {
  //   id: 11,
  //   name: "Entertainment",
  //   imgsrc: Guitar,
  //   num: 0,
  //   type:"Entertainment"
  // },
  {
    id: 12,
    name: "Career & Education",
    imgsrc: Graduation,
    num: 0,
    type:"CareerEducation"
  },
  {
    id: 13,
    name: "Docs For Associates to Review",
    imgsrc: List,
    num: 0,
    type:"Wishlist"
  },
];
