import React, { useState } from "react";
import Wealth from "../Wealth/Wealth";
import Capital from "../Capital/Capital";
import Mortgage from "../Mortgage/Mortgage";
import Business from "../Business/Business";
import "./ServicesHeader.css";

const ServicesHeader = () => {
  const [activeTab, setActiveTab] = useState("SG Wealth partners");

  const renderComponent = () => {
    switch (activeTab) {
      case "SG Wealth partners":
        return <Wealth />;
      case "SG Capital Advisory":
        return <Capital />;
      case "SG Mortgage Capital":
        return <Mortgage />;
      case "SG Tax & Business Services":
        return <Business />;
      default:
        return null;
    }
  };

  return (
    <div className="DataHeaderContainer">
      <div className="DataHeader">
        <ul>
          <li
            className={activeTab === "SG Wealth partners" ? "active" : ""}
            onClick={() => setActiveTab("SG Wealth partners")}
          >
            SG Wealth Partners
          </li>
          <li
            className={activeTab === "SG Capital Advisory" ? "active" : ""}
            onClick={() => setActiveTab("SG Capital Advisory")}
          >
            SG Capital Advisory
          </li>
          <li
            className={activeTab === "SG Mortgage Capital" ? "active" : ""}
            onClick={() => setActiveTab("SG Mortgage Capital")}
          >
            SG Mortgage Capital{" "}
          </li>
          <li
            className={activeTab === "SG Tax & Business Services" ? "active" : ""}
            onClick={() => setActiveTab("SG Tax & Business Services")}
          >
            SG Tax & Business Services{" "}
          </li>
        </ul>
      </div>
      <div className="DataContent">{renderComponent()}</div>
    </div>
  );
};

export default ServicesHeader;
