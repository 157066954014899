import React from "react";
import S2 from "../../assets/SG 5.0 webview Capital Advisory Banner.gif";
import AboutUs from "../AboutUs/AboutUs";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import "./Capital.css";

const Capital = () => {
  return (
    <div className="Capital">
      {/* <h1>SG Capital Advisory</h1>
      <h2>Don't wait to buy Real Estate. Buy Real Estate and wait.</h2> */}
      <div className="CapitalPic flex center">
        <img src={S2} alt="" />
      </div>
      <p className="section-headings-center">
        With over 25 years of business operations and management experience in
        Corporate, Private Clients and Foundation Consulting. Doug’s experience
        extends to the areas of integrated tax & wealth management, project
        management, business structure, investment procurement and due diligence
        of private offerings.
      </p>

      <hr />

      <div className="partnership">
        <h1>In partnership with SZAM Capital Partners</h1>
        <div className="capitalData">
          <div>
            <div className="head1">14</div>
            <div className="head2">Projects</div>
          </div>
          <div>
            <div className="head1">$80M</div>
            <div className="head2">Portfolio</div>
          </div>
          <div>
            <div className="head1">700</div>
            <div className="head2">Investors</div>
          </div>
          <div>
            <div className="head1">6</div>
            <div className="head2">Markets</div>
          </div>
        </div>
        <div className="read">
          <a href="/">
            Learn More <FaArrowUpRightFromSquare />
          </a>
        </div>
      </div>

      <hr />

      {/* <div className="logic">
        <h1>Introducing – ECO LOGIC HOMES</h1>
        <p>
          Our sustainable modular panel system significantly reduces waste and
          minimizes costs, while providing premium quality homes. Legal rental
          or secondary small homes right on your existing property.
        </p>
        <div className="read">
          <a href="/">
            Learn More <FaArrowUpRightFromSquare />
          </a>
        </div>
      </div> */}
      {/* <hr /> */}
      <AboutUs />
    </div>
  );
};

export default Capital;
