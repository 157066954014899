import React, { useEffect, useState } from "react";
import { FilesData } from "./FilesData";
import "./DataFiles.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ipv4 } from "../../utils/config";
import { ImSpinner9 } from "react-icons/im";
import { setSelectedFileType, setSelectedFolder } from "../../redux/userSlice";

const DataFiles = ({ selectedCategory, onFileTypeClick, reloadData }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder1, setSelectedFolder1] = useState(null);
  const userId = useSelector((state) => state.user.userDetails?.user_id);
  const userRole = useSelector((state) => state.user.userDetails?.role);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        let response;
        if (userRole === "Admin") {
          response = await axios.get(`${ipv4}/get_Allfiles`);
        } else {
          response = await axios.post(`${ipv4}/getUserFilesById`, {
            user_id: userId,
          });
        }
   
        setIsLoading(false);
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [reloadData, userRole, userId]);

  if (isLoading) {
    return (
      <div className="loader">
        <ImSpinner9 className="spinner" />
      </div>
    );
  }

  const getFileCount = (category) => {
    if (!userData) return 0;

    if (Array.isArray(userData)) {
      return userData.reduce(
        (total, user) => total + (user[category]?.files.length || 0),
        0
      );
    } else {
      return userData[category]?.files.length || 0;
    }
  };

  const handleFolderSelect = (folderName) => {
    setSelectedFolder1(folderName);
    dispatch(setSelectedFolder(folderName));
    console.log("selected folder:", folderName);
  };

  const handleFileTypeClick = (fileType, userData) => {
    dispatch(setSelectedFileType(fileType));
    onFileTypeClick(fileType, userData);
  };

  const handleClick = (file) => {
    let clickTimer;
    return (event) => {
      if (clickTimer) {
        clearTimeout(clickTimer);
        handleFileTypeClick(file.type, userData);
      } else {
        clickTimer = setTimeout(() => {
          handleFolderSelect(file.type);
          clickTimer = null;
        }, 300);
      }
    };
  };

  return (
    <div className="DataFiles">
      <div className="btn-line2">
        <div>
          {/* <h1>Data Files</h1> */}
        </div>
      </div>
      <div className="FilesDataContainer">
        {FilesData.map((file) => {
          const fileCount = getFileCount(file.type.replace(/\s+/g, ""));

          return (
            <div
              key={file.id}
              className={`FileItem ${
                selectedCategory === file.name ? "selected" : ""
              } ${selectedFolder1 === file.type ? "selected-folder" : ""}`}
              onClick={handleClick(file)}
            >
              <div className="FileCount">{fileCount}</div>
              <div className="FileImageContainer">
                <div>
                  <img
                    src={file.imgsrc}
                    alt={file.name}
                    className="FileImage"
                  />
                </div>
                <div className="FileName">
                  <h1>{file.name}</h1>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DataFiles;