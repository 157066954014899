import React from "react";
import VideoPic from "../../assets/VideoPic.png";
import Myths from "../../assets/myths.png";
import Play1 from "../../assets/Play1.png";
import Play2 from "../../assets/Play2.png";
import "./VideosData.css";

export const VideoDetails1 = [
  {
    id: 1,
    img: Myths,
    title: "Retirement Myths",
  },
  {
    id: 2,
    img: Myths,
    title: "TFSA Contribution or Mortgage Payment",
  },
  {
    id: 3,
    img: Myths,
    title: "TFSA VS RRSP",
  },
];

const VideosData = () => {
  return (
    <div className="VideosData">
      {/* <h1>Videos</h1> */}
      {/* <h2>Financial Planning 101</h2> */}
      <div className="ContactPic sentry-yt-videos">
        {/* <img src={VideoPic} alt="Contact" /> */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/6P9_WVAGDtM?si=1o0lhgFDF91tap6l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/UV_X6qCHX7g?si=oHM5QWN9gtzJeDod" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>

      <div className="VideoPlanning">
        <h3>Yotube Playlists</h3>
        <div className="VideoPlanning2">
          <div className="VideoPlanningItem2">
            <img src={Play1} alt={Play1} />
          </div>
          <div className="VideoPlanningItem2">
            <img src={Play2} alt={Play2} />
          </div>
        </div>
      </div>

      <div className="VideoPlanning">
        <h3>Retirement Planning</h3>
        <div className="VideoPlanning">
          {VideoDetails1.map((detail) => (
            <div key={detail.id} className="VideoPlanningItem">
              <img src={detail.img} alt={detail.title} />
              <p>{detail.title}</p>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="VideoPlanning">
        <h3>Insurance Planning</h3>
        <div className="VideoPlanning">
          {VideoDetails1.map((detail) => (
            <div key={detail.id} className="VideoPlanningItem">
              <img src={detail.img} alt={detail.title} />
              <p>{detail.title}</p>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="VideoPlanning">
        <h3>Investment Planning</h3>
        <div className="VideoPlanning">
          {VideoDetails1.map((detail) => (
            <div key={detail.id} className="VideoPlanningItem">
              <img src={detail.img} alt={detail.title} />
              <p>{detail.title}</p>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="VideoPlanning">
        <h3>Tax Planning</h3>
        <div className="VideoPlanning">
          {VideoDetails1.map((detail) => (
            <div key={detail.id} className="VideoPlanningItem">
              <img src={detail.img} alt={detail.title} />
              <p>{detail.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideosData;
